import { useState } from 'react';

import { useParams } from 'react-router-dom';

import { usePostBPMNProcessMutation } from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService';

import { DOCUMENTS_PARAMETERS_DECLARATIONS_FORM_ENUM } from 'containers/documents-declarations-form/documentsDeclarationsForm.utils';

import {
  DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_ENUM,
  ProcessParamMapType,
} from './documentsDeclarationsParameters.utils';

interface useParametersFormSubmitHandlerProps {
  isCancellable?: boolean;
}

const useParametersFormSubmitHandler = (props: useParametersFormSubmitHandlerProps) => {
  const [paramValues, setParamValues] = useState<Map<string, ProcessParamMapType>>(new Map());
  const { isCancellable } = props;

  const { id } = useParams();
  const [setParamsDeclaration, { isLoading: setParamsIsLoading }] = usePostBPMNProcessMutation();

  const handleFormSubmit = (
    formValues: Record<DOCUMENTS_PARAMETERS_DECLARATIONS_FORM_ENUM.BPMN_PROCESS | string, string>,
  ) => {
    if (formValues[DOCUMENTS_PARAMETERS_DECLARATIONS_FORM_ENUM.BPMN_PROCESS]) {
      const totalDays = formValues[
        DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_ENUM.TOTAL_DAYS_LIMIT
      ] as unknown as number;

      const needBuhApprove = formValues[
        DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_ENUM.NEED_BUH_APPROVE
      ] as unknown as boolean;

      setParamsDeclaration({
        id: id!,
        body: {
          isCancellable: Boolean(isCancellable),
          totalDaysLimit: totalDays,
          params: {
            bpmnProcess: formValues[DOCUMENTS_PARAMETERS_DECLARATIONS_FORM_ENUM.BPMN_PROCESS],
            paramValues: getParamValues(formValues, paramValues),
            cancelProcessParamValues: [
              {
                code: 'needBuhApprove',
                type: 'BOOL',
                value: needBuhApprove,
                numberOfDays: 0,
              },
            ],
          },
        },
      });
    }
  };

  return {
    isLoading: setParamsIsLoading,
    handleFormSubmit,
    setParamValues,
  };
};

const getParamValues = (
  formValues: Record<DOCUMENTS_PARAMETERS_DECLARATIONS_FORM_ENUM.BPMN_PROCESS | string, string>,
  paramValues: Map<string, ProcessParamMapType>,
) => {
  const updatedParams = new Map(paramValues);

  Object.entries(formValues).forEach(([fullKey, value]) => {
    const [mapKey, fieldKey] = fullKey.split('.');

    if (updatedParams.has(mapKey)) {
      const param = updatedParams.get(mapKey);

      if (param) {
        if (fieldKey === 'numberOfDays') {
          param.numberOfDays = Number.isInteger(value) ? Number(value) : param.numberOfDays;
        } else if (fieldKey === undefined && !param.value) {
          param.value = value;
        }

        updatedParams.set(mapKey, param);
      }
    }
  });

  return Array.from(updatedParams.values());
};

export default useParametersFormSubmitHandler;
