import { useEffect } from 'react';

import { formatComplexStatusesToFlat } from 'utils/filter.utils';
import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetKedoDocumentsQuery } from 'services/1c/cApiService';
import { GetKedoDocumentsPropsType } from 'services/1c/cApiService.types';

import { Form, FormInstance } from 'gazprom-ui-lib';

import { KEDO_TYPE_ENUM } from 'types/kedo.types';

import { FormResponseType } from './documentsVacations.utils';

interface UseGetDataProps {
  form: FormInstance<FormResponseType>;
}

const useGetData = (props: UseGetDataProps) => {
  const { form } = props;

  const [getKedoDocuments, { data, isFetching, isLoading, isUninitialized }] =
    useLazyGetKedoDocumentsQuery();

  const formData: FormResponseType | undefined = Form.useWatch([], form);

  const { debouncedValue: debouncedFormData, isDebouncing } = useDebounce(formData);

  useEffect(() => {
    if (debouncedFormData?.size) {
      const { filters, search, page, size, property, order } = debouncedFormData;

      const modifiedStatuses = formatComplexStatusesToFlat(filters?.statuses);

      const initialRequestBody: GetKedoDocumentsPropsType = {
        page,
        size,
        search: search,
        status: modifiedStatuses,
        employerId: filters?.employerIds,
        type: KEDO_TYPE_ENUM.VACATION,
      };

      if (property && order) {
        initialRequestBody.sort = [
          {
            order,
            property,
          },
        ];
      }

      getKedoDocuments(initialRequestBody);
    }
  }, [getKedoDocuments, debouncedFormData]);

  return {
    data,
    isFetching: isDebouncing || isFetching,
    isLoading: isUninitialized || isLoading,
  };
};

export default useGetData;
