import { FC } from 'react';

import { t } from 'tools/i18n';
import { getFiltersCount } from 'utils/filter.utils';

import { useGetCategoriesQuery } from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService';
import { GetDeclarationsPropsTypes } from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService.types';
import { useGetEmployersListQuery } from 'services/employer/employerApiService';

import { Flex, Icon, Input, InputProps, Select } from 'gazprom-ui-lib';

import Filters from 'containers/filters';
import FilterCheckboxGroup from 'containers/filters/filter-checkbox-group';
import { FilterCheckboxGroupOptions } from 'containers/filters/filter-checkbox-group/filterCheckboxGroup';
import FilterCustomWrapper from 'containers/filters/filter-custom-wrapper';
import FilterHeader from 'containers/filters/filter-header';

import { KEDO_TYPE_ENUM } from 'types/kedo.types';

import s from './DocumentsDeclarationsFilters.module.scss';

interface DocumentsDeclarationsFiltersProps {
  filters: Pick<GetDeclarationsPropsTypes, 'categoryId' | 'isPublished'> | null;
  search?: string;
  handleSearchChange?: InputProps['onChange'];
  setFilters: (
    values: Pick<GetDeclarationsPropsTypes, 'categoryId' | 'isPublished'> | null,
  ) => void;
  handleOrganizationChange?: (id: string) => void;
}

const DocumentsDeclarationsFilters: FC<DocumentsDeclarationsFiltersProps> = (props) => {
  const { filters, search, handleSearchChange, setFilters, handleOrganizationChange } = props;
  const { data: categories } = useGetCategoriesQuery({});

  const sectionFilterOptions = [
    { label: t('documents_declarations_for_kedo'), value: KEDO_TYPE_ENUM.KEDO },
    { label: t('documents_declarations_for_edo'), value: KEDO_TYPE_ENUM.BPMN },
    { label: t('documents_declarations_for_social_programs'), value: KEDO_TYPE_ENUM.SOCIAL },
  ];

  const { employersOptions } = useGetEmployersListQuery(
    { page: 1, size: 500 },
    { selectFromResult },
  );

  return (
    <div className={s.container}>
      <Flex gap="32" className={s.searchContainer}>
        <Input
          allowClear
          value={search}
          onChange={handleSearchChange}
          className={s.input}
          placeholder={t('common_search')}
          size="small"
          prefix={<Icon name="search" />}
        />
        {handleOrganizationChange && (
          <Select
            placeholder={t('common_choose_organization_from_list')}
            options={employersOptions}
            showSearch
            filterOption
            optionFilterProp="label"
            allowClear
            className={s.select}
            onChange={handleOrganizationChange}
          />
        )}
      </Flex>

      <Filters
        initialValues={filters}
        handleApply={setFilters}
        showClearAll
        overlay={
          <FilterCustomWrapper>
            <FilterHeader valueKey="status">{t('common_section')}</FilterHeader>
            <FilterCheckboxGroup
              usePadding={false}
              valueKey="type"
              options={sectionFilterOptions}
            />
            <FilterHeader valueKey="status">{t('common_categories')}</FilterHeader>
            <FilterCheckboxGroup
              usePadding={false}
              valueKey="categoryId"
              options={categories as FilterCheckboxGroupOptions[]}
            />
            <FilterHeader valueKey="status">{t('common_status')}</FilterHeader>
            <FilterCheckboxGroup
              usePadding={false}
              valueKey="isPublished"
              options={[
                { label: t('common_published'), value: 'true' },
                { label: t('common_not_published'), value: 'false' },
              ]}
            />
          </FilterCustomWrapper>
        }
        filtersCount={getFiltersCount(filters)}
      />
    </div>
  );
};

export default DocumentsDeclarationsFilters;

const selectFromResult = ({
  data,
  ...other
}: {
  data?: { content: { id: string; name: string }[] };
}) => ({
  employersOptions: data?.content?.map(({ name, id }) => ({ value: id, label: name })) ?? [],
  ...other,
});
