import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import { Flex, Form, Radio, Typography } from 'gazprom-ui-lib';

import { COMMUNITY_TARGET_AUDIENCE } from 'types/community.types';

import { MASS_SUBSCRIBE_INVITE_FORM_ENUM } from '../massSubscribeInviteForm.utils';

const MassSubscribeInviteFormGender = () => {
  return (
    <Flex vertical gap="8">
      <Flex gap="8" align="center">
        <Typography.Text weight="500" size="16">
          {t('community_target_audience_title')}
        </Typography.Text>
      </Flex>
      <Form.Item rules={[formRules.required]} name={MASS_SUBSCRIBE_INVITE_FORM_ENUM.GENDER}>
        <Radio.Group>
          <Radio value={COMMUNITY_TARGET_AUDIENCE.BOTH}>{t('community_target_audience_all')}</Radio>
          <Radio value={COMMUNITY_TARGET_AUDIENCE.FEMALE}>
            {t('community_target_audience_female')}
          </Radio>
          <Radio value={COMMUNITY_TARGET_AUDIENCE.MALE}>
            {t('community_target_audience_male')}
          </Radio>
        </Radio.Group>
      </Form.Item>
    </Flex>
  );
};

export default MassSubscribeInviteFormGender;
