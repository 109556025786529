import { t } from 'tools/i18n';

import { Form, Input, Typography } from 'gazprom-ui-lib';

import { DOCUMENTS_EDO_DECLARATIONS_FORM_ENUM } from '../documentsDeclarationsForm.utils';
import s from './DocumentsDeclarationsFormExecutor.module.scss';

interface Props {
  disabled?: boolean;
}

const DocumentsDeclarationsFormExecutor = (props: Props) => {
  const { disabled } = props;

  return (
    <>
      <div className={s.titleContainer}>
        <Typography.Title level={5}>
          {t('documents_declarations_for_edo_title_executor')}
        </Typography.Title>

        <Typography.Text size="12" weight="400" type="secondary">
          {t('common_optional')}
        </Typography.Text>
      </div>
      <Form.Item name={DOCUMENTS_EDO_DECLARATIONS_FORM_ENUM.EXECUTOR}>
        <Input
          placeholder={t('documents_declarations_for_edo_executor_placeholder')}
          disabled={disabled}
        />
      </Form.Item>
    </>
  );
};

export default DocumentsDeclarationsFormExecutor;
