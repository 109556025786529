import { prepareQueryParams } from 'utils/prepareQueryParams';

import cInitialApiService from 'services/1c/cApiService';
import {
  EditKedoArchiveDocPropsType,
  EditKedoArchiveDocResponseType,
  GetKedoArchiveDocsPropsType,
  GetKedoArchiveDocsResponseType,
} from 'services/kedo-archive-doc/kedoArchiveDocApiService.types';
import { createKedoArchiveDocUrl } from 'services/kedo-archive-doc/utils';

const kedoArchiveDocApiService = cInitialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getKedoArchiveDocs: builder.query<GetKedoArchiveDocsResponseType, GetKedoArchiveDocsPropsType>({
      query: (props) => ({
        url: createKedoArchiveDocUrl(`${prepareQueryParams(props)}`),
      }),
    }),
    editKedoArchiveDoc: builder.mutation<
      EditKedoArchiveDocResponseType,
      EditKedoArchiveDocPropsType
    >({
      query: ({ id, ...body }) => ({
        url: createKedoArchiveDocUrl(`/${id}`),
        method: 'PUT',
        body,
      }),
    }),
  }),
});

export const { useGetKedoArchiveDocsQuery, useEditKedoArchiveDocMutation } =
  kedoArchiveDocApiService;
