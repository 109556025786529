import { t } from 'tools/i18n';

import { Button, Flex, Modal, Tooltip, Typography } from 'gazprom-ui-lib';

import Bio from 'components/bio';

import { CommunityInviteType } from 'types/communityInvite.types';
import { CommunitySubscriberType } from 'types/communitySubscriber.types';

import ResendInviteButton from '../resend-invite-button';
import StatusTag from '../status-tag';
import { HandleOpenBlockUnblockModalProps } from '../table/table';
import { SEND_STATUS_MAP } from '../table/useGetTabTableColumns.utils';
import { CommunityManagementSubscribersEnumType } from '../tabs/tabs.utils';
import s from './SubscriberDetailsModal.module.scss';

type SubscriberDetailsModalProps = {
  tabType: CommunityManagementSubscribersEnumType;
  open: boolean;
  close: () => void;
  subscriber: CommunitySubscriberType | CommunityInviteType;
  openBlockUnblockModal: (props: HandleOpenBlockUnblockModalProps) => void;
};

const SubscriberDetailsModal = ({
  subscriber,
  open,
  close,
  tabType,
  openBlockUnblockModal,
}: SubscriberDetailsModalProps) => {
  const {
    employee: {
      person: { firstName, lastName, phone, email },
      division: { name: divisionName },
      employer: { name: employerName },
      position: { name: positionName },
    },
  } = subscriber;

  const renderStatusBar = () => {
    if (tabType === 'BLOCKED') {
      const { reasonBlock } = subscriber as CommunitySubscriberType;
      return (
        <Flex vertical gap="4">
          <StatusTag
            type="danger"
            iconName="passwordFilled"
            text={t('community_blocked_singular')}
          />
          <Typography.Text weight="400" size="14">
            {reasonBlock}
          </Typography.Text>
        </Flex>
      );
    } else if (tabType === 'INVITES') {
      const { status } = subscriber as CommunityInviteType;
      const inviteProps = SEND_STATUS_MAP[status];
      return <StatusTag {...inviteProps} />;
    }
  };

  const renderActions = () => {
    if (tabType === 'SUBSCRIBERS') {
      const { isModerator } = subscriber as CommunitySubscriberType;
      return (
        <>
          <Tooltip title={isModerator && t('community_can_not_block_moderator')} placement="top">
            <Button
              disabled={isModerator}
              onClick={() => openBlockUnblockModal({ modalType: 'block', subscriber })}
              danger
              fullWidth
              ghost
              leftIcon="passwordFilled">
              {t('common_block')}
            </Button>
          </Tooltip>
          <Button fullWidth ghost onClick={close}>
            {t('common_close')}
          </Button>
        </>
      );
    } else if (tabType === 'BLOCKED') {
      return (
        <>
          <Button
            onClick={() => openBlockUnblockModal({ modalType: 'unblock', subscriber })}
            fullWidth
            leftIcon="lockReset">
            {t('community_unblock')}
          </Button>
          <Button fullWidth ghost onClick={close}>
            {t('common_close')}
          </Button>
        </>
      );
    } else if (tabType === 'INVITES') {
      const { communityId, id: inviteId, status } = subscriber as CommunityInviteType;

      return (
        <>
          <ResendInviteButton
            closeModal={close}
            communityId={communityId}
            inviteId={inviteId}
            location="modal"
            inviteStatus={status}
          />
          <Button fullWidth ghost onClick={close}>
            {t('common_close')}
          </Button>
        </>
      );
    }
  };

  return (
    <Modal open={open} closable onCancel={close}>
      {renderStatusBar()}
      <Flex className={s.marginTop} vertical gap="24">
        <Bio
          employerName={employerName}
          firstName={firstName}
          lastName={lastName}
          staffName={positionName}
        />

        <Flex vertical>
          <Typography.Text type="secondary">{t('common_division')}</Typography.Text>
          <Typography.Text weight="500" size="14">
            {divisionName}
          </Typography.Text>
        </Flex>

        <Flex className={s.phoneMailWrapper} wrap="wrap" justify="space-between" gap="16">
          <Flex vertical>
            <Typography.Text type="secondary">{t('common_phone')}</Typography.Text>
            <Typography.Text weight="500" size="14">
              {phone}
            </Typography.Text>
          </Flex>
          <Flex vertical>
            <Typography.Text type="secondary">{t('common_email')}</Typography.Text>
            <Typography.Text weight="500" size="14">
              {email}
            </Typography.Text>
          </Flex>
        </Flex>

        <Flex gap="8">{renderActions()}</Flex>
      </Flex>
    </Modal>
  );
};

export default SubscriberDetailsModal;
