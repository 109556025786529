import { useParams } from 'react-router-dom';

import { Form } from 'gazprom-ui-lib';

import DocumentsDeclarationsFormCategory from 'containers/documents-declarations-form/documents-declarations-form-category';
import DocumentsDeclarationsFormCheck from 'containers/documents-declarations-form/documents-declarations-form-check';
import DocumentsDeclarationsFormDescription from 'containers/documents-declarations-form/documents-declarations-form-description';
import DocumentsDeclarationsFormExecutor from 'containers/documents-declarations-form/documents-declarations-form-executor';
import DocumentsDeclarationsFormLink from 'containers/documents-declarations-form/documents-declarations-form-link';
import DocumentsDeclarationsFormName from 'containers/documents-declarations-form/documents-declarations-form-name';
import DocumentsDeclarationsFormOrganization from 'containers/documents-declarations-form/documents-declarations-form-organization';
import { DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM } from 'containers/documents-declarations-form/documentsDeclarationsForm.utils';
import WithLoader from 'containers/wrappers/with-loader';

import DocumentsDeclarationsButtons from 'pages/documents/documents-declarations/documents-declarations-buttons';
import useCommonFormSubmitHandler from 'pages/documents/documents-declarations/useCommonFormSubmitHandler.hook';

import { KEDO_TYPE_ENUM } from 'types/kedo.types';

import usePermissionCheck from '../../usePermissionCheck';
import s from './CommonDescriptionLocalTab.module.scss';
import { DOCUMENTS_DECLARATIONS_EDO_COMMON_FORM_NAME } from './common.utils';
import useCommonData from './useCommonData';

const CommonDescriptionLocalTabEdit = () => {
  const [form] = Form.useForm();

  const { id } = useParams<{ id: string }>();
  const { isLoading, statementData, createRoute } = useCommonData({ form });

  const statementType = id ? statementData?.type : KEDO_TYPE_ENUM.BPMN;
  const { isFetching, handleFormSubmit } = useCommonFormSubmitHandler({
    createRoute: createRoute,
    type: statementType,
  });

  const isAdminRole = usePermissionCheck(['admin_mobile', 'admin', 'kedo_admin']);
  const isKedoModeratorRole = usePermissionCheck(['kedo_moderator']);
  const isOnlyKedoModeratorRole = isKedoModeratorRole && !isAdminRole;

  const isFormDisabled =
    isOnlyKedoModeratorRole || (isAdminRole && statementData?.defaultStatementId === null);

  return (
    <WithLoader isLoading={isLoading}>
      <Form
        form={form}
        onFinish={handleFormSubmit}
        id={DOCUMENTS_DECLARATIONS_EDO_COMMON_FORM_NAME}>
        <div className={s.container}>
          <DocumentsDeclarationsFormLink
            defaultStatementId={statementData?.defaultStatementId}
            type={statementData?.type}
          />
          <DocumentsDeclarationsFormOrganization
            disabled={true}
            defaultValue={statementData?.ownerEmployerId}
          />
          <DocumentsDeclarationsFormName disabled={isFormDisabled} />
          <DocumentsDeclarationsFormCategory type={statementData?.type} disabled={isFormDisabled} />
          <DocumentsDeclarationsFormDescription disabled={isFormDisabled} />
          <DocumentsDeclarationsFormExecutor disabled={isFormDisabled} />
          <DocumentsDeclarationsFormCheck
            defaultValue={false}
            className={s.hidden}
            formName={DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM.ACCESS}
            label=""
          />
          <Form.Item name={DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM.DEFAULT_STATEMENT_ID} noStyle />
        </div>
        <DocumentsDeclarationsButtons isFetching={isFetching} />
      </Form>
    </WithLoader>
  );
};

export default CommonDescriptionLocalTabEdit;
