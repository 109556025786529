import { useNavigate, useParams } from 'react-router-dom';
import { DOCUMENTS_VACATIONS_ROUTE } from 'routes/documents/list';
import { t } from 'tools/i18n';

import { useGetKedoDocumentByIdQuery } from 'services/1c/cApiService';

import { Button, Flex } from 'gazprom-ui-lib';

import KedoStatusTag from 'components/kedo-status-tag';
import ContentHeader from 'containers/content-header';
import DocumentView from 'containers/document-view';
import WithLoader from 'containers/wrappers/with-loader';
import WithOverflow from 'containers/wrappers/with-overflow';

import s from './DocumentsVacationsDetails.module.scss';
import Files from './containers/files';
import Person from './containers/person';

const DocumentsVacationsDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data: kedoDocument,
    isLoading,
    isError,
  } = useGetKedoDocumentByIdQuery(id!, { skip: !id });

  return (
    <WithOverflow>
      <ContentHeader align="flex-start" showBorder>
        <ContentHeader.Title goBackLink={DOCUMENTS_VACATIONS_ROUTE}>
          {t('documents_vacations_details_title')}
        </ContentHeader.Title>
        <ContentHeader.Actions>
          {kedoDocument && (
            <KedoStatusTag
              currentKedoStatus={{
                ...kedoDocument.status,
                title: kedoDocument.status.name,
              }}
              size="30"
            />
          )}
        </ContentHeader.Actions>
      </ContentHeader>
      <WithLoader isLoading={isLoading}>
        <DocumentView isError={isError}>
          <DocumentView.Content>
            <Flex vertical gap="24">
              <DocumentView.Employee employee={kedoDocument?.employee} />
              <Files data={kedoDocument} />
              {kedoDocument?.assignments.map((assignment) => {
                return <Person key={assignment.id} {...assignment} />;
              })}
            </Flex>
          </DocumentView.Content>
          <Button
            type="secondary"
            className={s.closeButton}
            onClick={() => navigate(DOCUMENTS_VACATIONS_ROUTE)}>
            {t('common_close')}
          </Button>
        </DocumentView>
      </WithLoader>
    </WithOverflow>
  );
};

export default DocumentsVacationsDetails;
