import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import { Form, TextArea, Typography } from 'gazprom-ui-lib';

import { DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM } from '../documentsDeclarationsForm.utils';
import s from './DocumentsDeclarationsFormDescription.module.scss';

interface Props {
  disabled?: boolean;
}

const DocumentsDeclarationsFormDescription = (props: Props) => {
  const { disabled } = props;
  const form = Form.useFormInstance();
  const textField = Form.useWatch(DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM.DESCRIPTION, form);

  return (
    <>
      <div className={s.titleContainer}>
        <Typography.Title level={5}>{t('common_description')}</Typography.Title>
        <Typography.Text size="12" weight="400" type="secondary">
          {t('common_symbol_count', { current: textField?.length ?? 0, total: 255 })}
        </Typography.Text>
      </div>
      <Form.Item
        name={DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM.DESCRIPTION}
        rules={[formRules.required]}>
        <TextArea
          placeholder={t('common_enter_text')}
          className={s.textArea}
          maxLength={255}
          disabled={disabled}
        />
      </Form.Item>
    </>
  );
};

export default DocumentsDeclarationsFormDescription;
