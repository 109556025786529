import { FC } from 'react';

import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';

import { Button } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';

import { DOCUMENTS_DECLARATIONS_ADDITIONAL_FORM_NAME } from 'pages/documents/documents-declarations/documents-declarations-additional/documentsDeclarationsAdditional.utils';
import { TEMPLATES_TAB_KEY } from 'pages/documents/documents-declarations/useTemplateTab.hook';

import { ADDITIONAL_TAB, COMMON_TAB } from '../../documentsDeclarationsSocial.utils';
import { DOCUMENTS_DECLARATIONS_SOCIAL_COMMON_FORM_NAME } from '../common/common.utils';

interface HeaderProps {
  activeTab: string;
}

const Header: FC<HeaderProps> = (props) => {
  const { activeTab } = props;

  const { id } = useParams();

  let formName = '';

  const notTemplateTab = activeTab !== TEMPLATES_TAB_KEY;
  if (notTemplateTab) {
    if (activeTab === COMMON_TAB) {
      formName = DOCUMENTS_DECLARATIONS_SOCIAL_COMMON_FORM_NAME;
    }
    if (activeTab === ADDITIONAL_TAB) {
      formName = DOCUMENTS_DECLARATIONS_ADDITIONAL_FORM_NAME;
    }
  }

  return (
    <ContentHeader align="flex-start">
      <ContentHeader.Title goBackLink={-1}>
        {t(
          id
            ? 'documents_declarations_social_programs_edit_title'
            : 'documents_declarations_social_programs_title',
        )}
      </ContentHeader.Title>
      <ContentHeader.Actions>
        {notTemplateTab && (
          <Button leftIcon="ok" htmlType="submit" form={formName}>
            {t('common_save')}
          </Button>
        )}
      </ContentHeader.Actions>
    </ContentHeader>
  );
};

export default Header;
