import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import { Flex, Form, TextArea, Typography } from 'gazprom-ui-lib';

import { ADDITIONAL_DOCUMENTS_ENUM } from '../../utils';
import s from './Name.module.scss';

const Name = () => {
  const form = Form.useFormInstance();
  const nameField: string | undefined = Form.useWatch(ADDITIONAL_DOCUMENTS_ENUM.NAME, form);

  return (
    <Form.Item
      label={
        <Flex align="center" justify="space-between" className={s.container}>
          <Typography.Text size="14" type="primary" weight="500">
            {t('common_document_name')}
          </Typography.Text>
          <Typography.Text size="12" type="secondary">
            {t('common_symbol_count', {
              current: nameField?.length ?? 0,
              total: 240,
            })}
          </Typography.Text>
        </Flex>
      }
      name={ADDITIONAL_DOCUMENTS_ENUM.NAME}
      normalize={(e: string) => e.trimStart()}
      rules={[formRules.required]}>
      <TextArea placeholder={t('common_enter_text')} maxLength={240} />
    </Form.Item>
  );
};

export default Name;
