import { ReactNode } from 'react';

import { TIconsLiteral } from 'gazprom-ui-lib';

import { FileType } from './file.types';
import { ExecutorType } from './review-task.types';
import { EmployeeType } from 'types/employee.types';
import { KEDO_TYPE_ENUM, KedoAttributeValidation } from 'types/kedo.types';

export type CurrentKedoStatusType = {
  comment: string | null;
  createdAt: string | null;
  code?: string | null;
  description: string | null;
  state: string;
  status: string;
  title: string;
  titleAlt: null | string;
};
export type DeclarationsTypes = {
  id: string;
  createdAt: string;
  updatedAt: string;
  creator: string;
  editor: string;
  categoryId: string;
  categoryName: string;
  isPublish: boolean;
  name: string;
  description: string;
  role1cCode: string;
  hasCommission: boolean;
  type: string;
  defaultExecutor: string;
};

export type DeclarationsTypesLocal = DeclarationsTypes & {
  ownerEmployerName: string;
};

export type KedoCategoryType = {
  id: string;
  createdAt: string;
  updatedAt: string;
  creator: string;
  editor: string;
  name: string;
  isPublish: boolean;
  icon?: string | null;
};

export type DocumentsDeclarationsAdditionalSectionType = {
  key: string;
  label: ReactNode;
  children: ReactNode;
  num: number;
  isInitial: boolean;
};

export type DeclarationParamType = {
  code: string;
  type: string;
  value: string;
  numberOfDays: number;
};

export type DeclarationType = {
  id: string;
  ownerEmployerId: string | null;
  ownerEmployerName: string | null;
  defaultStatementId: string | null;
  createdAt: string;
  updatedAt: string;
  creator: string;
  editor: string;
  categoryId: string;
  categoryName: string;
  isPublish: boolean;
  name: string;
  description: string;
  role1cCode: string;
  hasCommission: boolean;
  type: KEDO_TYPE_ENUM;
  defaultExecutor: string;
  attributes: [
    {
      id: string;
      statementId: string;
      createdAt: string;
      updatedAt: string;
      creator: string;
      editor: string;
      name: string;
      type: string;
      isPublish: boolean;
      isMandatory: boolean;
      inputMask: string;
      placeholder: string;
      tooltip: string;
      num: number;
      code: string;
    },
  ];
  docTemplate: {
    createdAt: string;
    id: string;
    name: string;
  } | null;
  bpmnProcess: string;
  cancelProcessParams: { code: string; type: string; value: string }[];
  params: DeclarationParamType[];
  isCancellable: boolean;
  hasDigitalForm: boolean;
  totalDaysLimit: number;
};

export type AttributeType = {
  id: string;
  statementId: string;
  createdAt: string;
  updatedAt: string;
  creator: string;
  editor: string;
  name: string;
  type: string;
  isPublish: boolean;
  isMandatory: boolean;
  inputMask: string;
  placeholder: string;
  tooltip: string;
  num: number;
  code: string;
  validation?: KedoAttributeValidation;
};

export type CreateAttributeType = {
  inputMask: string;
  isMandatory: boolean;
  name: string;
  num: number;
  tooltip: string;
  type: string;
  validationCode?: string;
};

export type StatementType = {
  id: string;
  statement: {
    id: string;
    name: string;
  };
  category: {
    id: string;
    name: string;
  };
  personId: string;
  employee: EmployeeType;
  createdAt: string;
  updatedAt: string;
  currentKedoStatus: CurrentKedoStatusType;
  type: string;
  files: {
    fileKey: string;
    fileName: string;
    signs: {
      key: string;
      date: string;
      owner: string;
    }[];
    type: string | null;
    description: string | null;
    attributeId: string | null;
    num: number | null;
    docnumberDocPrint: string | null;
    dateDoc: string | null;
  }[];
  totalDeadlineAt: string | null;
};

export type PersonComponentParamsType = {
  button: ReactNode;
  isMe?: boolean;
  icon: TIconsLiteral;
  type: DOCUMENT_VIEW_PERSON_ENUM;
  date?: string;
};

export type FileDataType = {
  title: string;
  description: string;
  icon: TIconsLiteral;
  isLoaded: boolean;
};

export type ProcessType = {
  code: string;
  name: string;
  description: string;
};

export type ProcessParamType = {
  bpmnProcess: string;
  type: string;
  name: string;
  code: string;
  num: number;
  isStage: boolean;
  children: ProcessParamType[];
};

export enum PROCESS_PARAM_TYPE {
  STRING = 'STRING',
  BOOLEAN = 'BOOLEAN',
}

export type ParamValueType = {
  code: string;
  type: PROCESS_PARAM_TYPE;
  value: string | boolean | null;
};

export enum DOCUMENT_VIEW_PERSON_ENUM {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

export type KedoDocumentType = {
  createDate: string;
  employee: EmployeeType;
  files: FileType[];
  id: string;
  name: string;
  status: CurrentKedoStatusType & {
    name: string;
  };
  updateDate: string;
  assignments: {
    comment: string | null;
    createdAt: string;
    employee: EmployeeType;
    executor: ExecutorType;
    finishDate?: string;
    id: string;
    num: number | null;
    status: {
      code: string;
      name: string;
      description: string;
      state: string;
    };
    type: string;
    title: string;
    files: FileType[];
  }[];
};
