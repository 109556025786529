import { createMediaUploadUrl1c } from 'utils/createMediaUrl.utils';

export const getUploadLink = (kedoDocumentId: string) => {
  return createMediaUploadUrl1c(`kedo-files/kedo-document/${kedoDocumentId}`);
};

export const getMetaLink = () => {
  return createMediaUploadUrl1c(`kedo-files/meta`);
};

export const getDownloadLink = (kedoDocumentId: string) => {
  return createMediaUploadUrl1c(`kedo-files/download/kedo-document/${kedoDocumentId}`);
};
