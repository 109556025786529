import { useState } from 'react';

import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';
import { useDebounce } from 'utils/useDebounce.hook';

import { useGetEmployees1cQuery } from 'services/1c/cApiService';
import { CEmployeeType, GetEmployees1cResponseType } from 'services/1c/cApiService.types';

import { Form, Select, Typography } from 'gazprom-ui-lib';

import { ADDITIONAL_DOCUMENTS_ENUM } from '../../utils';

const Signatory = () => {
  const [search, setSearch] = useState<string>('');

  const { debouncedValue: debouncedSearch } = useDebounce(search);

  const { options } = useGetEmployees1cQuery(
    { fio: debouncedSearch, page: 1, size: 500 },
    { selectFromResult },
  );

  return (
    <Form.Item
      label={
        <Typography.Text size="14" type="primary" weight="500">
          {t('common_signatory')}
        </Typography.Text>
      }
      name={ADDITIONAL_DOCUMENTS_ENUM.SGNER_ID}
      normalize={(e: string) => e.trimStart()}
      rules={[formRules.required]}>
      <Select
        placeholder={t('common_select_second')}
        loading={false}
        options={options}
        onSearch={setSearch}
        showSearch
        filterOption
        optionFilterProp="label"
      />
    </Form.Item>
  );
};

export default Signatory;

const selectFromResult = ({ data, ...other }: { data?: GetEmployees1cResponseType }) => ({
  ...other,
  options:
    data?.employees?.map(({ person, id }: CEmployeeType) => ({
      value: id,
      label: `${person.lastName} ${person.firstName} ${person.patronymic}`,
    })) ?? [],
});
