import cInitialApiService from 'services/1c/cApiService';

import { GetAuthoritiesPropsType, GetAuthoritiesResponseType } from './cryptoApiService.types';

import { createCryptoUrl } from './utils';

const cryptoApiService = cInitialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getAuthorities: builder.query<GetAuthoritiesResponseType, GetAuthoritiesPropsType>({
      query: () => ({
        url: createCryptoUrl('/authorities'),
      }),
      providesTags: [],
    }),
  }),
});

export const { useGetAuthoritiesQuery } = cryptoApiService;
