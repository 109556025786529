import { createApi } from '@reduxjs/toolkit/query/react';
import { prepareQueryParams } from 'utils/prepareQueryParams';

import { C_BASE_QUERY_WITH_AUTH } from 'store/services/utils';

import {
  GetEmployees1cPropsType,
  GetEmployees1cResponseType,
  GetEmployer1cPropsType,
  GetEmployer1cResponseType,
  GetGroupCitizenshipPropsType,
  GetGroupCitizenshipResponseType,
  GetKedoDocumentByIdPropsType,
  GetKedoDocumentByIdResponseType,
  GetKedoDocumentStatusesPropsType,
  GetKedoDocumentStatusesResponseType,
  GetKedoDocumentsPropsType,
  GetKedoDocumentsResponseType,
  GetPersons1cPropsType,
  GetPersons1cResponseType,
  GetSocialRequestById1cPropsType,
  GetSocialRequestById1cResponseType,
  GetSocialRequests1cPropsType,
  GetSocialRequests1cResponseType,
  GetStatementsRequestType,
  GetStatementsResponseType,
  GetTaskAssignmentByIdRequestType,
  GetTaskAssignmentByIdResponseType,
  GetTaskAssignmentsRequestType,
  GetTaskAssignmentsResponseType,
  GetTaskByIdRequestType,
  GetTaskByIdResponseType,
  GetTaskStatuses1cPropsType,
  GetTaskStatuses1cResponseType,
  GetWorkerEmployeesResponseType,
  SocialRequestAssignApprove1cPropsType,
  SocialRequestAssignApprove1cResponseType,
  SocialRequestAssignCommission1cPropsType,
  SocialRequestAssignCommission1cResponseType,
  SocialRequestAssignExecutor1cPropsType,
  SocialRequestAssignExecutor1cResponseType,
  SocialRequestCorrection1cPropsType,
  SocialRequestCorrection1cResponseType,
  SocialRequestExecute1cPropsType,
  SocialRequestExecute1cResponseType,
  SocialRequestReject1cPropsType,
  SocialRequestReject1cResponseType,
  UpdateEmployer1cByIdPropsType,
  UpdateEmployer1cByIdResponseType,
  UpdateExecutorPropsType,
  UpdateExecutorResponseType,
  VerdictAssignmentRequestType,
  VerdictAssignmentResponseType,
} from './cApiService.types';
import { C_SERVICE_TAGS, C_SERVICE_TAGS_LIST } from 'constants/serviceTags';

export const API_NAME = '1cApi';

const cInitialApiService = createApi({
  reducerPath: API_NAME,
  baseQuery: C_BASE_QUERY_WITH_AUTH,
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getEmployees1c: builder.query<GetEmployees1cResponseType, GetEmployees1cPropsType>({
      query: (params) => ({
        url: `employee1c${prepareQueryParams(params)}`,
      }),
    }),
    getWorkerEmployees: builder.query<GetWorkerEmployeesResponseType, void>({
      query: () => ({
        url: `me/employees`,
      }),
      providesTags: [C_SERVICE_TAGS.GET_EMPLOYEES],
    }),
    getTaskStatuses1c: builder.query<GetTaskStatuses1cResponseType, GetTaskStatuses1cPropsType>({
      query: (params) => ({
        url: `status${prepareQueryParams(params)}`,
      }),
    }),
    getPersons1c: builder.query<GetPersons1cResponseType, GetPersons1cPropsType>({
      query: (params) => ({
        url: `person1c${prepareQueryParams(params)}`,
      }),
    }),
    updateEmployer1cById: builder.mutation<
      UpdateEmployer1cByIdResponseType,
      UpdateEmployer1cByIdPropsType
    >({
      query: ({ id, ...body }) => ({
        url: `employer1c/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [C_SERVICE_TAGS.GET_EMPLOYERS],
    }),
    getEmployer1c: builder.query<GetEmployer1cResponseType, GetEmployer1cPropsType>({
      query: (params) => ({
        url: `employer1c${prepareQueryParams(params)}`,
      }),
      providesTags: [C_SERVICE_TAGS.GET_EMPLOYERS],
    }),
    getSocialRequests1c: builder.query<
      GetSocialRequests1cResponseType,
      GetSocialRequests1cPropsType
    >({
      query: (params) => ({
        url: `social-task${prepareQueryParams(params)}`,
      }),
    }),
    getSocialRequestById1c: builder.query<
      GetSocialRequestById1cResponseType,
      GetSocialRequestById1cPropsType
    >({
      query: (id) => ({
        url: `social-task/${id}`,
      }),
      providesTags: [C_SERVICE_TAGS.SOCIAL_REQUEST_BY_ID],
    }),
    socialRequestCorrection1c: builder.mutation<
      SocialRequestCorrection1cResponseType,
      SocialRequestCorrection1cPropsType
    >({
      query: (body) => ({
        url: `social-task/correction`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [C_SERVICE_TAGS.SOCIAL_REQUEST_BY_ID],
    }),
    socialRequestReject1c: builder.mutation<
      SocialRequestReject1cResponseType,
      SocialRequestReject1cPropsType
    >({
      query: (body) => ({
        url: `social-task/reject`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [C_SERVICE_TAGS.SOCIAL_REQUEST_BY_ID],
    }),
    socialRequestCommission1c: builder.mutation<
      SocialRequestAssignCommission1cResponseType,
      SocialRequestAssignCommission1cPropsType
    >({
      query: (body) => ({
        url: `social-task/assign-commission`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [C_SERVICE_TAGS.SOCIAL_REQUEST_BY_ID],
    }),
    socialRequestApprove1c: builder.mutation<
      SocialRequestAssignApprove1cResponseType,
      SocialRequestAssignApprove1cPropsType
    >({
      query: (body) => ({
        url: `social-task/approve`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [C_SERVICE_TAGS.SOCIAL_REQUEST_BY_ID],
    }),
    socialRequestExecute1c: builder.mutation<
      SocialRequestExecute1cResponseType,
      SocialRequestExecute1cPropsType
    >({
      query: (body) => ({
        url: `social-task/execute`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [C_SERVICE_TAGS.SOCIAL_REQUEST_BY_ID],
    }),
    socialRequestAssignExecutor1c: builder.mutation<
      SocialRequestAssignExecutor1cResponseType,
      SocialRequestAssignExecutor1cPropsType
    >({
      query: ({ taskId, executorId }) => ({
        url: `social-task/${taskId}`,
        method: 'PATCH',
        body: { executorId },
      }),
      invalidatesTags: [C_SERVICE_TAGS.SOCIAL_REQUEST_BY_ID],
    }),
    getStatements: builder.query<GetStatementsResponseType, GetStatementsRequestType>({
      query: (params) => ({
        url: `kedo/task${prepareQueryParams(params)}`,
      }),
    }),
    getKedoDocuments: builder.query<GetKedoDocumentsResponseType, GetKedoDocumentsPropsType>({
      query: (params) => ({
        url: `kedo-document${prepareQueryParams(params)}`,
      }),
    }),
    getKedoDocumentById: builder.query<
      GetKedoDocumentByIdResponseType,
      GetKedoDocumentByIdPropsType
    >({
      query: (id) => ({
        url: `kedo-document/${id}`,
      }),
    }),
    getKedoDocumentStatuses: builder.query<
      GetKedoDocumentStatusesResponseType,
      GetKedoDocumentStatusesPropsType
    >({
      query: () => ({
        url: `kedo-document/status`,
      }),
    }),
    getTaskAssignments: builder.query<
      GetTaskAssignmentsResponseType,
      GetTaskAssignmentsRequestType
    >({
      query: (params) => ({
        url: `me/task-assignment${prepareQueryParams(params)}`,
      }),
    }),
    verdictAssignment: builder.mutation<
      VerdictAssignmentResponseType,
      VerdictAssignmentRequestType
    >({
      query: ({ id, myEmployeeId, comment, status }) => ({
        url: `task-assignment/${id}/verdict?myEmployeeId=${myEmployeeId}`,
        method: 'POST',
        body: {
          comment,
          status,
        },
      }),
      invalidatesTags: [C_SERVICE_TAGS.ASSIGNMENT_BY_ID],
    }),
    getTaskAssignmentById: builder.query<
      GetTaskAssignmentByIdResponseType,
      GetTaskAssignmentByIdRequestType
    >({
      query: ({ id, executorId }) => ({
        url: `me/task-assignment/${id}?executorId=${executorId}`,
      }),
      providesTags: [C_SERVICE_TAGS.ASSIGNMENT_BY_ID],
    }),
    getTaskById: builder.query<GetTaskByIdResponseType, GetTaskByIdRequestType>({
      query: (id) => ({
        url: `kedo/task/${id}`,
      }),
      providesTags: [C_SERVICE_TAGS.STATEMENT_TASK_BY_ID],
    }),
    getGroupCitizenship: builder.query<
      GetGroupCitizenshipResponseType,
      GetGroupCitizenshipPropsType
    >({
      query: () => ({
        url: `citizenship`,
      }),
    }),
    updateExecutor: builder.mutation<UpdateExecutorResponseType, UpdateExecutorPropsType>({
      query: ({ executorId, body }) => ({
        url: `kedo/task/update-executor/${executorId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [C_SERVICE_TAGS.STATEMENT_TASK_BY_ID],
    }),
  }),
  tagTypes: C_SERVICE_TAGS_LIST,
});

export const getWorkerEmployeesSelector = cInitialApiService.endpoints.getWorkerEmployees.select();

export const {
  useGetTaskStatuses1cQuery,
  useSocialRequestAssignExecutor1cMutation,
  useSocialRequestExecute1cMutation,
  useSocialRequestApprove1cMutation,
  useSocialRequestCommission1cMutation,
  useSocialRequestCorrection1cMutation,
  useSocialRequestReject1cMutation,
  useLazyGetSocialRequestById1cQuery,
  useLazyGetEmployees1cQuery,
  useGetEmployees1cQuery,
  useLazyGetPersons1cQuery,
  useLazyGetEmployer1cQuery,
  useLazyGetSocialRequests1cQuery,
  useLazyGetStatementsQuery,
  useLazyGetTaskAssignmentsQuery,
  useGetTaskByIdQuery,
  useUpdateEmployer1cByIdMutation,
  useGetTaskAssignmentByIdQuery,
  useVerdictAssignmentMutation,
  useGetGroupCitizenshipQuery,
  useUpdateExecutorMutation,
  useGetWorkerEmployeesQuery,
  useLazyGetKedoDocumentsQuery,
  useGetKedoDocumentStatusesQuery,
  useGetKedoDocumentByIdQuery,
} = cInitialApiService;

export default cInitialApiService;
