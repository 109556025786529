import { useState } from 'react';

import { DECLARATIONS_TYPE_TAB_ENUM } from 'routes/settings/list';
import { t } from 'tools/i18n';

import { Button } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';

import ModalCreate from '../containers/modal-create';

const DocumentsDeclarationsHeader = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleModal = () => setIsModalOpen(!isModalOpen);

  return (
    <>
      <ContentHeader>
        <ContentHeader.Title>{t('common_declaration_types')}</ContentHeader.Title>
        <ContentHeader.Actions>
          <Button leftIcon="plus" onClick={handleModal}>
            {t('documents_declarations_add_declarations')}
          </Button>
        </ContentHeader.Actions>
      </ContentHeader>
      <ModalCreate
        open={isModalOpen}
        close={handleModal}
        type={DECLARATIONS_TYPE_TAB_ENUM.COMMON}
      />
    </>
  );
};

export default DocumentsDeclarationsHeader;
