import { useAuth } from 'react-oidc-context';

const usePermissionCheck = (roles: string[]) => {
  const { user } = useAuth();

  const userRoles = user?.profile?.roles as string[] | undefined;

  return roles.some((role) => userRoles?.includes(role));
};

export default usePermissionCheck;
