import { kedoRoles } from 'roles/documents.roles';

import SignCheck from 'pages/sign-check';

import { RouteConfig } from '../routes';
import { SIGN_CHECK_ROUTE } from './list';

export const signCheckRoutes: RouteConfig[] = [
  {
    path: SIGN_CHECK_ROUTE,
    element: <SignCheck />,
    ...kedoRoles,
  },
];
