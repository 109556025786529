import { FC } from 'react';

import { useMatch, useParams } from 'react-router-dom';
import { SETTINGS_DECLARATIONS_KEDO_BY_ID_ROUTE } from 'routes/settings/list';
import { t } from 'tools/i18n';

import { Button } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';

import { DOCUMENTS_DECLARATIONS_ADDITIONAL_FORM_NAME } from 'pages/documents/documents-declarations/documents-declarations-additional/documentsDeclarationsAdditional.utils';
import { DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_NAME } from 'pages/documents/documents-declarations/documents-declarations-parameters/documentsDeclarationsParameters.utils';
import { TEMPLATES_TAB_KEY } from 'pages/documents/documents-declarations/useTemplateTab.hook';

import { ADDITIONAL_TAB, COMMON_TAB, PARAMETERS_TAB } from '../../documentsDeclarationsEdo.utils';
import { DOCUMENTS_DECLARATIONS_EDO_COMMON_FORM_NAME } from '../common-description-common-tab/common.utils';

interface HeaderProps {
  activeTab: string;
}

const Header: FC<HeaderProps> = (props) => {
  const { activeTab } = props;

  const { id } = useParams();

  const isKedo = !!useMatch(SETTINGS_DECLARATIONS_KEDO_BY_ID_ROUTE);

  let formName = '';

  const notTemplateTab = activeTab !== TEMPLATES_TAB_KEY;
  if (notTemplateTab) {
    if (activeTab === COMMON_TAB) {
      formName = DOCUMENTS_DECLARATIONS_EDO_COMMON_FORM_NAME;
    }
    if (activeTab === PARAMETERS_TAB) {
      formName = DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_NAME;
    }
    if (activeTab === ADDITIONAL_TAB) {
      formName = DOCUMENTS_DECLARATIONS_ADDITIONAL_FORM_NAME;
    }
  }

  let headerTitle = t('documents_declarations_for_edo_title');

  if (id) {
    headerTitle = t('documents_declarations_for_edo_edit_title');
  }

  if (isKedo && id) {
    headerTitle = t('documents_declarations_for_kedo_edit_title');
  }

  return (
    <ContentHeader align="flex-start">
      <ContentHeader.Title goBackLink={-1}>{headerTitle}</ContentHeader.Title>
      <ContentHeader.Actions>
        {notTemplateTab && (
          <Button leftIcon="ok" htmlType="submit" form={formName}>
            {t('common_save')}
          </Button>
        )}
      </ContentHeader.Actions>
    </ContentHeader>
  );
};

export default Header;
