import { useState } from 'react';

import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';
import useHandleRequestWithModal from 'utils/useHandleRequestWithModal.hook';

import { useRemoveDocumentMutation } from 'services/recruit/recruitApiService';

import { handleRequest } from 'gazprom-ui-lib';

import { RecruitDocumentType } from 'types/recruit.types';

interface Props {
  docInfo?: RecruitDocumentType | null;
  onCancel: () => void;
}

const useRemove = (props: Props) => {
  const { docInfo, onCancel } = props;

  const { id } = useParams<{ id: string }>();

  const [isRemoveOpen, setIsRemoveOpen] = useState<boolean>(false);

  const [remove, { isLoading }] = useRemoveDocumentMutation();

  const handleOpenRemove = () => setIsRemoveOpen((prev) => !prev);

  const handleSuccess = useHandleRequestWithModal({
    onSuccessModalConfig: {
      title: { children: t('common_successfully_removed') },
    },
  });

  const onSuccess = () => {
    handleSuccess();
    onCancel();
    handleOpenRemove();
  };

  const handleRemove = () => {
    const kedoDocumentId = docInfo?.document.id;
    const recruitLinkKedoDocumentId = docInfo?.id;

    if (id && kedoDocumentId && recruitLinkKedoDocumentId) {
      remove({ id, kedoDocumentId, recruitLinkKedoDocumentId }).then(handleRequest({ onSuccess }));
    }
  };

  return {
    isRemoveOpen,
    isRemoveLoading: isLoading,
    handleOpenRemove,
    handleRemove,
  };
};

export default useRemove;
