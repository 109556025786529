import { t } from 'tools/i18n';

import { Form } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';
import FiltersV2 from 'containers/filters-v2';
import WithOverflow from 'containers/wrappers/with-overflow';

import { KedoArchiveDocType } from 'types/kedoArchiveDoc.types';

import s from './DocumentsArchiveInfo.module.scss';
import Filters from './containers/filters';
import { DEFAULT_FORM_STATE, DEFAULT_SORT, FormResponseType } from './documentsArchiveInfo.utils';
import useColumns from './useColumns.hook';
import useGetData from './useGetData.hook';

const DocumentsArchiveInfo = () => {
  const [form] = Form.useForm<FormResponseType>();
  const columns = useColumns();
  const { kedoArchiveDocs, kedoArchiveDocsIsFetching } = useGetData({ form });

  return (
    <WithOverflow>
      <ContentHeader>
        <ContentHeader.Title>{t('documents_archive_info')}</ContentHeader.Title>
      </ContentHeader>
      <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
        <Filters />
        <FiltersV2.Table<KedoArchiveDocType, FormResponseType>
          fallbackSort={DEFAULT_SORT}
          className={s.tableWrapper}
          dataSource={kedoArchiveDocs?.items}
          columns={columns}
          rowKey="id"
          total={kedoArchiveDocs?.totalCount}
          loading={kedoArchiveDocsIsFetching}
        />
      </FiltersV2>
    </WithOverflow>
  );
};

export default DocumentsArchiveInfo;
