import { useState } from 'react';

import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';
import { getFio } from 'utils/getFio.utils';
import { truncateText } from 'utils/truncateText.utils';
import { useDebounce } from 'utils/useDebounce.hook';

import { useTargetSubscribeMutation } from '../../../../../../../../store/services/communities/communitiesApiService';
import { useGetEmployees1cQuery } from 'services/1c/cApiService';
import { useInviteUserMutation } from 'services/communities/communitiesApiService';

import { Select } from 'gazprom-ui-lib';

import { CommunityManagementSubscribersEnumType } from '../../../tabs/tabs.utils';
import s from './InviteSubscribeUser.module.scss';
import { RenderOption, filterOptionHansdler } from './inviteUser.utils';

const DEFAULT_PAGINATION = { page: 1, size: 100 };
const MAX_FIO_LENGTH = 35;
const MAX_POSITION_LENGTH = 35;

type InviteSubscribeUserProps = {
  tabType: CommunityManagementSubscribersEnumType;
};

const InviteSubscribeUser = ({ tabType }: InviteSubscribeUserProps) => {
  const { id: communityId } = useParams<{ id: string }>() as { id: string };

  const [fioSearch, setFioSearch] = useState('');
  const { debouncedValue: debouncedFioSearch, isDebouncing } = useDebounce(fioSearch);

  const { data: { employees = [] } = {}, isFetching } = useGetEmployees1cQuery({
    ...DEFAULT_PAGINATION,
    fio: debouncedFioSearch,
  });

  const [inviteUser] = useInviteUserMutation();
  const [targetSubscribe] = useTargetSubscribeMutation();

  const handleInvite = async (employeeId: string) => {
    try {
      if (tabType === 'INVITES') {
        await inviteUser({ communityId, params: { employeeId } }).unwrap();
      } else if (tabType === 'SUBSCRIBERS') {
        await targetSubscribe({ communityId, params: { employeeId } }).unwrap();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const preparedEmployees = employees.map(
    ({
      id: employeeId,
      person: { firstName, lastName, patronymic },
      position: { name: positionName },
    }) => {
      const fio = getFio({ firstName, lastName, patronymic, safe: false, type: 'full' });
      return {
        label: (
          <RenderOption
            employeeId={employeeId}
            fio={truncateText(fio, MAX_FIO_LENGTH)}
            position={truncateText(positionName, MAX_POSITION_LENGTH)}
            handleInvite={handleInvite}
          />
        ),
        value: employeeId,
        searchValue: fio,
      };
    },
  );

  const isLoading = isFetching || isDebouncing;

  return (
    <Select
      size="small"
      loading={isLoading}
      searchValue={fioSearch}
      onSearch={setFioSearch}
      showSearch
      className={s.inviteSubscribeUser}
      value={null}
      onSelect={(value) => {
        console.log(value);
      }}
      // @ts-expect-error custom option render + custom searchValue
      filterOption={filterOptionHansdler}
      placeholder={t('community_subscribe_user')}
      // TODO.Ilya раскомментить перевод как аналитика разберётся с бэком про точечный invite / subscribe
      //   placeholder={t(tabType === "SUBSCRIBERS" ? "community_subscribe_user" : 'community_invite_user')}

      options={preparedEmployees}
    />
  );
};

export default InviteSubscribeUser;
