import { useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';
import { useParams } from 'react-router-dom';
import { DECLARATIONS_TYPE_TAB_ENUM } from 'routes/settings/list';
import { t } from 'tools/i18n';

import { useGetDeclarationTypeQuery } from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService';

import { Tabs } from 'gazprom-ui-lib';

import WithOverflow from 'containers/wrappers/with-overflow';

import { KEDO_TYPE_ENUM } from 'types/kedo.types';

import DocumentsDeclarationsAdditional from '../documents-declarations-additional';
import DocumentsDeclarationsParameters from '../documents-declarations-parameters';
import usePopoverHook from '../usePopover.hook';
import useTemplateTab from '../useTemplateTab.hook';
import s from './DocumentsDeclarationsEdo.module.scss';
import CommonDescriptionCommonTab from './containers/common-description-common-tab';
import CommonDescriptionLocalTabCreate from './containers/common-description-local-tab-create';
import CommonDescriptionLocalTabEdit from './containers/common-description-local-tab-edit';
import Header from './containers/header';
import { ADDITIONAL_TAB, COMMON_TAB, PARAMETERS_TAB } from './documentsDeclarationsEdo.utils';

const DocumentsDeclarationsEdo = () => {
  const { id, declarationTypeTab } = useParams<{
    id: string;
    declarationTypeTab: DECLARATIONS_TYPE_TAB_ENUM;
  }>();

  const { data } = useGetDeclarationTypeQuery(id ?? skipToken);

  const isLocalTab = declarationTypeTab === DECLARATIONS_TYPE_TAB_ENUM.LOCAL;

  const CREATE_TABS = [
    {
      key: COMMON_TAB,
      label: t('common_common_description'),
      children: isLocalTab ? <CommonDescriptionLocalTabCreate /> : <CommonDescriptionCommonTab />,
    },
  ];

  const EDIT_TABS = [
    {
      key: COMMON_TAB,
      label: t('common_common_description'),
      children: isLocalTab ? <CommonDescriptionLocalTabEdit /> : <CommonDescriptionCommonTab />,
    },
    {
      key: PARAMETERS_TAB,
      label: t('documents_declarations_parameters_tab'),
      children: <DocumentsDeclarationsParameters />,
    },
    {
      key: ADDITIONAL_TAB,
      label: t('common_additional_fields'),
      children: <DocumentsDeclarationsAdditional />,
    },
  ];

  const defaultActiveTab = id ? EDIT_TABS[0].key : CREATE_TABS[0].key;

  const [activeTab, setActiveTab] = useState(defaultActiveTab);

  const { popoverIsOpen, popoverHandler } = usePopoverHook({ activeTab });
  const templateTab = useTemplateTab({ popoverIsOpen, onClose: popoverHandler });

  let tabs = [...EDIT_TABS, templateTab];

  if (data?.type === KEDO_TYPE_ENUM.KEDO) {
    tabs.splice(-1);
  }

  return (
    <WithOverflow>
      <Header activeTab={activeTab} />
      <Tabs
        className={s.tabs}
        items={id ? tabs : CREATE_TABS}
        type="card"
        onTabClick={setActiveTab}
        activeKey={activeTab}
      />
    </WithOverflow>
  );
};

export default DocumentsDeclarationsEdo;
