import dayjs from 'dayjs';
import { t } from 'tools/i18n';

import { TableProps } from 'gazprom-ui-lib';

import TableText from 'components/table/table-text';

import { AuthorityType } from 'types/authority.types';

const useColumns = (): TableProps<AuthorityType>['columns'] => {
  const nameRenderer = (name: string) => (
    <TableText size="16" weight="500">
      {name}
    </TableText>
  );

  const dateRenderer = (date: string) => (
    <TableText size="14" weight="500">
      {dayjs(date).format('DD.MM.YYYY')}
    </TableText>
  );

  // const actionRenderer = () => {
  //   return (
  //     <Button className={s.button} leftIcon="checklist" type="link" size="small">
  //       {t('certification_authorities_check_availability')}
  //     </Button>
  //   );
  // };

  return [
    {
      title: t('certification_authorities_name'),
      dataIndex: 'subject',
      key: 'subject',
      width: 500,
      render: nameRenderer,
    },
    {
      title: t('common_active_date'),
      dataIndex: 'notBefore',
      key: 'notBefore',
      align: 'center',
      width: 140,
      render: dateRenderer,
    },
    {
      title: t('common_finish_date'),
      dataIndex: 'notAfter',
      key: 'notAfter',
      align: 'center',
      width: 140,
      render: dateRenderer,
    },
    // {
    //   width: 140,
    //   render: actionRenderer,
    // },
  ];
};

export default useColumns;
