import { EmployeeType } from './employee.types';
import { ExecutorType } from './review-task.types';

export enum TASK_ASSIGNMENT_TYPE_ENUM {
  EMPLOYMENT_SIGN_HEAD = 'EMPLOYMENT_SIGN_HEAD',
  SIGN_HEAD = 'SIGN_HEAD',
  SIGN_HEAD_NO_REFUSE = 'SIGN_HEAD_NO_REFUSE',
  INFO = 'INFO',
  APPROVE = 'APPROVE',
  SIGN_EMPLOYEE = 'SIGN_EMPLOYEE',
  CANCEL_ORG_REQUEST = 'CANCEL_ORG_REQUEST',
}

export type TaskAssignmentType = {
  id: string;
  num: number;
  executor: ExecutorType;
  type: TASK_ASSIGNMENT_TYPE_ENUM;
  createdAt: string;
  status: {
    code: string;
    name: string;
    description: string;
    state: string;
  };
  finishDate: string;
  comment: string;
  title: string;
  employee: EmployeeType;
  deadlineAt: string | null;
};
