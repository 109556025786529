import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import { Form, Select, Typography } from 'gazprom-ui-lib';

import { ADDITIONAL_DOCUMENTS_ENUM, DOCUMENT_TYPES } from '../../utils';

const Type = () => {
  return (
    <Form.Item
      label={
        <Typography.Text size="14" type="primary" weight="500">
          {t('common_document_type')}
        </Typography.Text>
      }
      name={ADDITIONAL_DOCUMENTS_ENUM.DOC_TYPE}
      normalize={(e: string) => e.trimStart()}
      rules={[formRules.required]}>
      <Select
        placeholder={t('common_select_second')}
        options={DOCUMENT_TYPES}
        showSearch
        filterOption
        optionFilterProp="label"
      />
    </Form.Item>
  );
};

export default Type;
