import { useEffect, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';
import { DECLARATIONS_TYPE_TAB_ENUM } from 'routes/settings/list';
import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetDeclarationTypesWithOrgQuery } from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService';
import { GetDeclarationsWithOrgPropsTypes } from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService.types';

import { SORT_ENUM, SortType } from 'types/sort.types';

const INITIAL_REQUEST_BODY = {
  page: 1,
  size: 10,
  property: 'createdAt',
  order: SORT_ENUM.DESC,
};

type RequestBodyType = SortType & {
  page: number;
  size: number;
  defaultStatementIds?: string[];
  property?: string;
  order?: SORT_ENUM;
};

const useLinkedData = () => {
  const { id, declarationTypeTab } = useParams<{
    id: string;
    declarationTypeTab: DECLARATIONS_TYPE_TAB_ENUM;
  }>();

  const [requestBody, setRequestBody] = useState<RequestBodyType>(INITIAL_REQUEST_BODY);

  const [request, { data, isLoading, isFetching }] = useLazyGetDeclarationTypesWithOrgQuery();
  const { debouncedValue: debouncedRequestBody } = useDebounce(requestBody);

  useEffect(() => {
    if (id && declarationTypeTab === DECLARATIONS_TYPE_TAB_ENUM.COMMON) {
      const prepareRequestBody: GetDeclarationsWithOrgPropsTypes = {
        page: 1,
        size: 10,
        defaultStatementIds: [id],
      };

      if (debouncedRequestBody.property && debouncedRequestBody.order) {
        if (debouncedRequestBody.property === 'createdAt') {
          prepareRequestBody.sort = [
            {
              order: debouncedRequestBody.order,
              property: `kedo_statement.create_date`,
            },
          ];
        }
      }

      request(prepareRequestBody);
    }
  }, [debouncedRequestBody.order, debouncedRequestBody.property, declarationTypeTab, id, request]);

  const pagination = useMemo(
    () => ({
      defaultCurrent: requestBody.page,
      defaultPageSize: requestBody.size,
      current: requestBody.page,
      total: data?.totalCount,
    }),
    [data?.totalCount, requestBody.page, requestBody.size],
  );

  return {
    id,
    dataSource: data?.items,
    pagination,
    isLoading,
    isFetching,
    setRequestBody,
  };
};

export default useLinkedData;
