import { useMemo } from 'react';

import { t } from 'tools/i18n';

import { useGetAuthoritiesQuery } from 'services/crypto/cryptoApiService';

import { Form } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';
import FiltersV2 from 'containers/filters-v2';
import WithOverflow from 'containers/wrappers/with-overflow';

import { AuthorityType } from 'types/authority.types';

import Table from './containers/table';
import { DEFAULT_FORM_STATE, FormResponseType } from './settingsCertificationAuthorities.utils';
import useSetDefaultValues from './useSetDefaultValues.hook';

const SettingsCertificationAuthorities = () => {
  const { data: authorities, isFetching, isLoading } = useGetAuthoritiesQuery();

  const [form] = Form.useForm();

  const formResponse: FormResponseType | undefined = Form.useWatch([], form);

  useSetDefaultValues({ form });

  const items = useMemo(() => {
    if (formResponse?.search) {
      return (authorities ?? []).filter((item: AuthorityType) => {
        return item.subject.toLowerCase().includes(formResponse.search.toLowerCase());
      });
    } else {
      return authorities;
    }
  }, [authorities, formResponse?.search]);

  return (
    <>
      <WithOverflow>
        <ContentHeader>
          <ContentHeader.Title>{t('certification_authorities_directory')}</ContentHeader.Title>
        </ContentHeader>
        <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
          <FiltersV2.TableFilters>
            <FiltersV2.Search />
          </FiltersV2.TableFilters>
          <Table isInitialLoading={isLoading} dataSource={items} loading={isFetching} />
        </FiltersV2>
      </WithOverflow>
    </>
  );
};

export default SettingsCertificationAuthorities;
