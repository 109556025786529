import { GetBPMNProcessesResponseType } from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService.types';

export enum DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM {
  ORGANIZATION = 'ownerEmployerId',
  NAME = 'name',
  CATEGORY = 'categoryId',
  DESCRIPTION = 'description',
  COMMISSION_APPROVAL = 'hasCommission',
  DEFAULT_EXECUTOR = 'defaultExecutor',
  ACCESS = 'isPublish',
  DEFAULT_STATEMENT_ID = 'defaultStatementId',
}

export enum DOCUMENTS_EDO_DECLARATIONS_FORM_ENUM {
  EXECUTOR = 'role1cCode',
}

export enum DOCUMENTS_ADDITIONAL_DECLARATIONS_FORM_ENUM {
  ID = 'id',
  NUM = 'num',
  FIELD_NAME = 'name',
  TYPE = 'type',
  MASK = 'inputMask',
  TOOLTIP = 'tooltip',
  REQUIRED = 'isMandatory',
  VALIDATION_CODE = 'validationCode',
}

export enum DOCUMENTS_PARAMETERS_DECLARATIONS_FORM_ENUM {
  BPMN_PROCESS = 'bpmnProcess',
}

export interface DocumentsSocialDeclarationsFormResponse {
  [DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM.NAME]: string;
  [DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM.CATEGORY]: string;
  [DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM.DESCRIPTION]: string;
  [DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM.COMMISSION_APPROVAL]: boolean;
  [DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM.DEFAULT_EXECUTOR]?: string;
  [DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM.ACCESS]: boolean;
}

export interface DocumentsEdoDeclarationsFormResponse {
  [DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM.NAME]: string;
  [DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM.CATEGORY]: string;
  [DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM.DESCRIPTION]: string;
  [DOCUMENTS_EDO_DECLARATIONS_FORM_ENUM.EXECUTOR]?: string;
  [DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM.ACCESS]: boolean;
}

export type CommonDocumentsFormResponse =
  | DocumentsSocialDeclarationsFormResponse
  | DocumentsEdoDeclarationsFormResponse;

export const transformData = (data: GetBPMNProcessesResponseType) => {
  const dataObject: Record<string, GetBPMNProcessesResponseType[0]> = {};
  const options = data?.map((element) => {
    const { code, name } = element.process;
    dataObject[code] = element;
    return { label: name, value: code };
  });

  return { options, dataObject };
};
