import { t } from 'tools/i18n';

import { useReinviteUserMutation } from 'services/communities/communitiesApiService';

import { Button, ButtonProps } from 'gazprom-ui-lib';

import { CommunityInviteStatus } from 'types/communityInvite.types';

type ResentInviteButtonProps = {
  inviteStatus: CommunityInviteStatus;
  location: 'table' | 'modal';
  inviteId: string;
  communityId: string;
  closeModal?: () => void;
};

type ResendInviteButtonStylesValue = {
  type: ButtonProps['type'];
  size: ButtonProps['size'];
};

const ResendInviteButtonStyles: Record<
  ResentInviteButtonProps['location'],
  ResendInviteButtonStylesValue
> = {
  modal: {
    size: 'middle',
    type: 'primary',
  },
  table: {
    size: 'small',
    type: 'link',
  },
};

const ResendInviteButton = ({
  inviteStatus,
  location,
  inviteId,
  communityId,
  closeModal,
}: ResentInviteButtonProps) => {
  const [reinviteUser, { isLoading }] = useReinviteUserMutation();

  const handleReinvite = async () => {
    try {
      await reinviteUser({ inviteId, communityId }).unwrap();

      if (location === 'modal' && closeModal) {
        closeModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const props = ResendInviteButtonStyles[location];

  const loadingModal = location === 'modal' && isLoading;
  const loadingTable = location === 'table' && isLoading;

  if (inviteStatus === 'ACCEPTED') {
    return null;
  }

  return (
    <Button
      {...props}
      loading={loadingModal}
      onClick={(e) => {
        e.stopPropagation();
        handleReinvite();
      }}
      disabled={loadingTable}
      leftIcon="refresh">
      {t('community_retry_invitation_action')}
    </Button>
  );
};

export default ResendInviteButton;
