import { prepareQueryParams } from 'utils/prepareQueryParams';

import cInitialApiService from 'services/1c/cApiService';
import { downloadFileUsingRtk } from 'services/utils';

import {
  DownloadRecruitReportPropsType,
  GetRecruitByIdPropsType,
  GetRecruitByIdResponseType,
  GetRecruitStatusesPropsType,
  GetRecruitStatusesResponseType,
  GetRecruitsPropsType,
  GetRecruitsResponseType,
  RemoveDocumentPropsType,
  RemoveDocumentResponseType,
  SaveAndSendRecruitByIdPropsType,
  SaveRecruitByIdPropsType,
  SendRecruitById,
} from './recruitApiService.types';
import { C_SERVICE_TAGS } from 'constants/serviceTags';

import { createRecruitUrl } from './utils';

const recruitApiService = cInitialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getRecruits: builder.query<GetRecruitsResponseType, GetRecruitsPropsType>({
      query: (params) => ({
        url: createRecruitUrl(`${prepareQueryParams(params)}`),
      }),
    }),
    getRecruitById: builder.query<GetRecruitByIdResponseType, GetRecruitByIdPropsType>({
      query: (id) => ({
        url: createRecruitUrl(`/${id}`),
      }),
      providesTags: [C_SERVICE_TAGS.GET_RECRUIT_BY_ID],
    }),
    saveRecruitById: builder.mutation<void, SaveRecruitByIdPropsType>({
      query: ({ id, body, params }) => ({
        url: createRecruitUrl(`/${id}/kedo-document${prepareQueryParams(params)}`),
        method: 'POST',
        body,
      }),
      invalidatesTags: [C_SERVICE_TAGS.GET_RECRUIT_BY_ID],
    }),
    saveAndSendRecruitById: builder.mutation<void, SaveAndSendRecruitByIdPropsType>({
      query: ({ id, body, params }) => ({
        url: createRecruitUrl(`/${id}/kedo-document${prepareQueryParams(params)}`),
        method: 'PUT',
        body,
      }),
      invalidatesTags: [C_SERVICE_TAGS.GET_RECRUIT_BY_ID],
    }),
    sendRecruitById: builder.mutation<void, SendRecruitById>({
      query: ({ id, ...params }) => ({
        url: createRecruitUrl(`/${id}/kedo-document/start-process${prepareQueryParams(params)}`),
        method: 'PUT',
      }),
      invalidatesTags: [C_SERVICE_TAGS.GET_RECRUIT_BY_ID],
    }),
    getRecruitStatuses: builder.query<GetRecruitStatusesResponseType, GetRecruitStatusesPropsType>({
      query: () => ({
        url: createRecruitUrl(`/status`),
      }),
    }),
    downloadRecruitReport: builder.mutation<void, DownloadRecruitReportPropsType>({
      query({ filename, ...body }) {
        return {
          url: 'report/recruit',
          method: 'POST',
          body,
          responseHandler: downloadFileUsingRtk(filename),
          cache: 'no-cache',
        };
      },
    }),
    removeDocument: builder.mutation<RemoveDocumentResponseType, RemoveDocumentPropsType>({
      query({ id, ...params }) {
        return {
          url: `recruit/${id}/kedo-document${prepareQueryParams(params)}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: [C_SERVICE_TAGS.GET_RECRUIT_BY_ID],
    }),
  }),
});

export const {
  useGetRecruitsQuery,
  useLazyGetRecruitsQuery,
  useGetRecruitByIdQuery,
  useGetRecruitStatusesQuery,
  useDownloadRecruitReportMutation,
  useSaveRecruitByIdMutation,
  useRemoveDocumentMutation,
  useSendRecruitByIdMutation,
  useSaveAndSendRecruitByIdMutation,
} = recruitApiService;
