import { useNavigate } from 'react-router-dom';
import {
  DECLARATIONS_TYPE_TAB_ENUM,
  getSettingsDeclarationsEdoEditRoute,
  getSettingsDeclarationsKedoEditRoute,
  getSettingsDeclarationsSocialEditRoute,
} from 'routes/settings/list';
import { t } from 'tools/i18n';

import { Button, Flex, Icon, Typography } from 'gazprom-ui-lib';

import { KEDO_TYPE_ENUM } from 'types/kedo.types';

import s from './DocumentsDeclarationsFormLink.module.scss';

interface Props {
  type?: string;
  defaultStatementId?: string | null;
}

const DocumentsDeclarationsFormLink = (props: Props) => {
  const { defaultStatementId, type } = props;

  const navigate = useNavigate();

  if (!defaultStatementId) {
    return null;
  }

  const handleNavigate = () => {
    switch (type) {
      case KEDO_TYPE_ENUM.SOCIAL: {
        navigate(
          getSettingsDeclarationsSocialEditRoute(
            defaultStatementId!,
            DECLARATIONS_TYPE_TAB_ENUM.COMMON,
          ),
        );
        break;
      }
      case KEDO_TYPE_ENUM.KEDO: {
        navigate(
          getSettingsDeclarationsKedoEditRoute(
            defaultStatementId!,
            DECLARATIONS_TYPE_TAB_ENUM.COMMON,
          ),
        );
        break;
      }
      case KEDO_TYPE_ENUM.BPMN:
      default: {
        navigate(
          getSettingsDeclarationsEdoEditRoute(
            defaultStatementId!,
            DECLARATIONS_TYPE_TAB_ENUM.COMMON,
          ),
        );
      }
    }
  };

  return (
    <Flex justify="space-between" className={s.wrapper}>
      <Flex gap="8" align="center" className={s.container}>
        <Icon name="info" />
        <Typography.Text size="14" type="secondary">
          {t('documents_declarations_replace_common')}
        </Typography.Text>
      </Flex>

      <Button type="link" leftIcon="arrowLink" onClick={handleNavigate}>
        {t('documents_declarations_go_to_declaration')}
      </Button>
    </Flex>
  );
};

export default DocumentsDeclarationsFormLink;
