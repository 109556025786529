import { useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';

import { useLazyGetEmployersListQuery } from 'services/employer/employerApiService';

import { Form, Select, Typography } from 'gazprom-ui-lib';

import { selectEmployersListFromResult } from 'pages/employee-groups/employee-groups-create/containers/employees/containers/filters/filters.utils';

import { DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM } from '../documentsDeclarationsForm.utils';

interface Props {
  defaultValue?: string | null;
  disabled?: boolean;
}

const DocumentsDeclarationsFormOrganization = (props: Props) => {
  const { defaultValue, disabled } = props;

  const { id } = useParams<{ id: string }>();

  const [getEmployers, employersResult] = useLazyGetEmployersListQuery({
    selectFromResult: selectEmployersListFromResult,
  });

  useEffect(() => {
    getEmployers({ page: 1, size: 500 });
  }, [getEmployers]);

  return (
    <Form.Item
      name={DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM.ORGANIZATION}
      label={<Typography.Title level={5}>{t('common_organization')}</Typography.Title>}
      initialValue={defaultValue}>
      <Select
        placeholder={t('common_select_organization')}
        options={employersResult.employersOptions}
        showSearch
        filterOption
        allowClear={!id}
        optionFilterProp="label"
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default DocumentsDeclarationsFormOrganization;
