import { useNavigate, useParams } from 'react-router-dom';
import { DECLARATIONS_TYPE_TAB_ENUM, getDeclarationsTabTypeRoute } from 'routes/settings/list';
import { t } from 'tools/i18n';

import { Tabs } from 'gazprom-ui-lib';

import CommonTab from './containers/common-tab';
import LocalTab from './containers/local-tab';
import DocumentsDeclarationsHeader from './documents-declarations-header';

const DocumentsDeclarations = () => {
  const navigate = useNavigate();
  const { declarationTypeTab } = useParams<{ declarationTypeTab: DECLARATIONS_TYPE_TAB_ENUM }>();

  const handleNavigateTab = (tab: string) =>
    navigate(getDeclarationsTabTypeRoute(tab as DECLARATIONS_TYPE_TAB_ENUM));

  const tabItems = [
    {
      key: DECLARATIONS_TYPE_TAB_ENUM.LOCAL,
      label: t('common_local'),
      children: <LocalTab />,
    },
    {
      key: DECLARATIONS_TYPE_TAB_ENUM.COMMON,
      label: t('common_common'),
      children: <CommonTab />,
    },
  ];

  return (
    <>
      <DocumentsDeclarationsHeader />
      <Tabs
        type="card"
        items={tabItems}
        onChange={handleNavigateTab}
        defaultActiveKey={declarationTypeTab}
      />
    </>
  );
};

export default DocumentsDeclarations;
