import { t } from 'tools/i18n';

import { Button, Flex, Form, ModalDefault } from 'gazprom-ui-lib';

import { RecruitDocumentType } from 'types/recruit.types';

import { ADDITIONAL_DOCUMENTS_ENUM } from '../../utils';
import File from '../file';
import Name from '../name';
import NumberAndDate from '../number-and-date';
import RemoveModal from '../remove-modal';
import Signatory from '../signatory';
import Type from '../type';
import s from './AddDocumentModal.module.scss';
import useDefaultValues from './useDefaultValues';
import useRemove from './useRemove';
import useSave from './useSave';

interface Props {
  open: boolean;
  onCancel: () => void;
  docInfo?: RecruitDocumentType | null;
}

const AddDocumentModal = (props: Props) => {
  const { open, docInfo, onCancel } = props;

  const [form] = Form.useForm();

  useDefaultValues({ form, docInfo });

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const { handleSaveAndSend, handleSave, isLoading } = useSave({ onCancel: handleCancel, docInfo });
  const { isRemoveOpen, isRemoveLoading, handleOpenRemove, handleRemove } = useRemove({
    docInfo,
    onCancel: handleCancel,
  });

  const onSave = () => form.validateFields().then(() => handleSave(form.getFieldsValue(), 'false'));
  const onSend = () => form.validateFields().then(() => handleSave(form.getFieldsValue(), 'true'));
  const onSaveAndSend = () =>
    form.validateFields().then(() => handleSaveAndSend(form.getFieldsValue()));

  const title = docInfo ? t('common_view_document') : t('social_program_new_add_document');
  const isSign = docInfo && docInfo?.orgSignStatus.code !== 'NOT_SET';
  const fistButton = docInfo ? (
    <Button
      fullWidth
      type="secondary"
      leftIcon="bin"
      loading={isLoading}
      onClick={handleOpenRemove}
      danger>
      {t('common_remove')}
    </Button>
  ) : (
    <Button fullWidth type="secondary" leftIcon="ok" loading={isLoading} onClick={onSave}>
      {t('common_save')}
    </Button>
  );

  const buttons = isSign ? (
    <Button fullWidth type="secondary" onClick={onCancel}>
      {t('common_close')}
    </Button>
  ) : (
    <>
      {fistButton}
      <Button
        fullWidth
        leftIcon="signature"
        loading={isLoading}
        onClick={docInfo ? onSaveAndSend : onSend}>
        {t('common_save_and_send')}
      </Button>
    </>
  );

  return (
    <>
      <ModalDefault open={!!open} onCancel={handleCancel} width={520} closable className={s.modal}>
        <ModalDefault.Title>{title}</ModalDefault.Title>
        <Form form={form} className={s.content} disabled={!!isSign}>
          <Form.Item name={ADDITIONAL_DOCUMENTS_ENUM.KEDO_DOCUMENT_UUID} noStyle />
          <Name />
          <NumberAndDate />
          <Type />
          <Signatory />
          <File />
        </Form>
        <ModalDefault.Actions>
          <Flex gap="8">{buttons}</Flex>
        </ModalDefault.Actions>
      </ModalDefault>
      <RemoveModal
        open={isRemoveOpen}
        onCancel={handleOpenRemove}
        handleRemove={handleRemove}
        isLoading={isRemoveLoading}
      />
    </>
  );
};

export default AddDocumentModal;
