import { useCallback, useEffect, useState } from 'react';

import { t } from 'tools/i18n';
import { useDebounce } from 'utils/useDebounce.hook';

import { useEditKedoArchiveDocMutation } from 'services/kedo-archive-doc/kedoArchiveDocApiService';
import { EditKedoArchiveDocPropsType } from 'services/kedo-archive-doc/kedoArchiveDocApiService.types';

import { Input, InputProps, TableProps, Typography } from 'gazprom-ui-lib';

import TablePerson from 'components/table/table-person';

import { KedoArchiveDocType } from 'types/kedoArchiveDoc.types';

interface EditableCellProps {
  row: KedoArchiveDocType;
  field: keyof EditKedoArchiveDocPropsType;
  maxLength?: number;
}

const EditableCell = (props: EditableCellProps) => {
  const { row, field, maxLength } = props;

  const [localRow, setLocalRow] = useState(row);
  const { debouncedValue } = useDebounce(localRow, 700);
  const [editArchiveDocument] = useEditKedoArchiveDocMutation();

  const handleSave = useCallback(
    async (updatedRow: KedoArchiveDocType) => {
      const payload: EditKedoArchiveDocPropsType = {
        id: updatedRow.id,
        docName: updatedRow.docName,
        docNumber: updatedRow.docNumber,
        docDate: updatedRow.docDate,
        docType: updatedRow.docType,
      };
      await editArchiveDocument(payload);
    },
    [editArchiveDocument],
  );

  useEffect(() => {
    if (debouncedValue[field] !== row[field]) {
      handleSave(debouncedValue);
    }
  }, [debouncedValue, field, handleSave, row]);

  const handleChange: InputProps['onChange'] = (event) => {
    setLocalRow((prev) => ({
      ...prev,
      [field]: event.target.value,
    }));
  };

  return <Input maxLength={maxLength} value={localRow[field]} onChange={handleChange} />;
};

const useColumns = (): TableProps<KedoArchiveDocType>['columns'] => {
  const employeeRenderer = (_: unknown, row: KedoArchiveDocType) => {
    const { kedoArchiveDocEmployee, employerName } = row;

    return (
      <TablePerson
        firstName={kedoArchiveDocEmployee.name}
        lastName={kedoArchiveDocEmployee.lastName}
        position={null}
        department={employerName}
      />
    );
  };

  const documentNameRenderer = (name: string) => {
    return (
      <Typography.Text size="12" weight="500" type="secondary">
        {name}
      </Typography.Text>
    );
  };

  return [
    {
      title: t('common_employee'),
      dataIndex: 'employee',
      align: 'left',
      render: employeeRenderer,
    },
    {
      title: t('common_document_name'),
      dataIndex: 'docName',
      align: 'left',
      width: 300,
      render: documentNameRenderer,
    },
    {
      title: t('common_document_number'),
      dataIndex: 'docNumber',
      align: 'center',
      render: (_: string, row: KedoArchiveDocType) => (
        <EditableCell row={row} field="docNumber" maxLength={10} />
      ),
    },
    {
      title: t('common_document_date'),
      dataIndex: 'docDate',
      align: 'center',
      render: (_: string, row: KedoArchiveDocType) => <EditableCell row={row} field="docDate" />,
    },
    {
      title: t('common_document_type'),
      dataIndex: 'docType',
      align: 'center',
      render: (_: string, row: KedoArchiveDocType) => (
        <EditableCell row={row} field="docType" maxLength={6} />
      ),
    },
  ];
};

export default useColumns;
