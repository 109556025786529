import { t } from 'tools/i18n';

import { ColumnsType, Typography } from 'gazprom-ui-lib';

import KedoStatusTag from 'components/kedo-status-tag';
import TableDate from 'components/table/table-date';
import TablePerson from 'components/table/table-person';

import { KedoDocumentType } from 'types/documents.types';

const useColumns = (): ColumnsType<KedoDocumentType> => {
  const employeeRenderer = (employee: KedoDocumentType['employee']) => {
    const { person, employer } = employee;
    const { firstName, lastName } = person;
    const { name } = employer;

    return <TablePerson firstName={firstName} lastName={lastName} department={name} />;
  };

  const nameRenderer = (name: KedoDocumentType['name']) => {
    return (
      <Typography.Text size="12" weight="500">
        {name}
      </Typography.Text>
    );
  };

  const statusRenderer = (status: KedoDocumentType['status'] & { name: string }) => {
    return <KedoStatusTag currentKedoStatus={{ ...status, title: status.name }} size="24" />;
  };

  const dateRenderer = (date: KedoDocumentType['createDate'] | KedoDocumentType['updateDate']) => (
    <TableDate date={date} />
  );

  return [
    {
      title: t('common_employee'),
      dataIndex: 'employee',
      render: employeeRenderer,
    },
    {
      title: t('common_document_name'),
      dataIndex: 'name',
      render: nameRenderer,
    },
    {
      title: t('common_status'),
      dataIndex: 'status',
      render: statusRenderer,
    },
    {
      title: t('common_created'),
      dataIndex: 'createDate',
      align: 'center',
      render: dateRenderer,
      width: 130,
      sorter: true,
      defaultSortOrder: 'descend',
    },
  ];
};

export default useColumns;
