import { ReactNode } from 'react';

import { t } from 'i18next';

import { Form, Input, Typography } from 'gazprom-ui-lib';

import s from './StageDeadline.module.scss';

interface Props {
  name: string;
  label?: ReactNode;
  initialValue?: number;
}

const StageDeadline = (props: Props) => {
  const { name, label, initialValue } = props;

  const currentLabel = label || (
    <Typography.Text size="14" weight="500">
      {t('common_stage_deadline')}
    </Typography.Text>
  );

  return (
    <Form.Item name={name} label={currentLabel} initialValue={initialValue}>
      <Input
        onClick={(e) => e.stopPropagation()}
        placeholder={t('common_specify_working_days')}
        className={s.input}
      />
    </Form.Item>
  );
};

export default StageDeadline;
