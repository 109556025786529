import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import { Form, Input, Typography } from 'gazprom-ui-lib';

import { DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM } from '../documentsDeclarationsForm.utils';
import s from './DocumentsDeclarationsFormName.module.scss';

interface Props {
  disabled?: boolean;
}

const DocumentsDeclarationsFormName = (props: Props) => {
  const { disabled } = props;
  const form = Form.useFormInstance();
  const textField = Form.useWatch(DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM.NAME, form);

  return (
    <>
      <div className={s.titleContainer}>
        <Typography.Title level={5}>{t('common_name')}</Typography.Title>
        <Typography.Text size="12" weight="400" type="secondary">
          {t('common_symbol_count', { current: textField?.length ?? 0, total: 80 })}
        </Typography.Text>
      </div>
      <Form.Item name={DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM.NAME} rules={[formRules.required]}>
        <Input placeholder={t('common_enter_text')} maxLength={80} disabled={disabled} />
      </Form.Item>
    </>
  );
};

export default DocumentsDeclarationsFormName;
