import { useCallback, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { useAuth } from 'react-oidc-context';
import { useNavigate, useParams } from 'react-router-dom';
import { DOCUMENTS_STATEMENTS_ROUTE } from 'routes/documents/list';
import { NO_PERMISSION_ROUTE } from 'routes/routes';
import { t } from 'tools/i18n';

import { useGetTaskByIdQuery } from 'services/1c/cApiService';

import { Button, Flex, Icon, StepsProps, Typography } from 'gazprom-ui-lib';

import KedoStatusTag from 'components/kedo-status-tag';
import ContentHeader from 'containers/content-header';
import DocumentEmployerCancelModal from 'containers/document-employer-cancel-modal';
import DocumentView from 'containers/document-view';
import WithLoader from 'containers/wrappers/with-loader';
import WithOverflow from 'containers/wrappers/with-overflow';

import { GLOBAL_ROLES } from 'types/roles.types';

import s from './DocumentsView.module.scss';
import { getTabItems } from './documentsView.utils';

const NOT_FOUND_ERROR_STATUS = 404;
const NO_PERMISSION_ERROR_STATUS = 403;

const DocumentsView = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { user } = useAuth();

  const [modalType, setModalType] = useState<'' | 'employerCancel'>('');

  const { data: taskData, isLoading, isError, error } = useGetTaskByIdQuery(id!, { skip: !id });
  const { approvers, totalDeadlineAt } = taskData ?? {};

  const isPlannedGreaterThanCurrent = dayjs(dayjs()).isAfter(totalDeadlineAt);

  const userRoles = user?.profile?.roles as string[] | undefined;

  const userHasRightsToCancel = userRoles?.includes(GLOBAL_ROLES.KEDO_MODERATOR);
  const isTaskEmployerCanceled = taskData?.currentKedoStatus?.status === 'CANCEL_EMPLOYER';

  const showCancelButton = userHasRightsToCancel && !isTaskEmployerCanceled;

  const handleGoBack = useCallback(() => navigate(DOCUMENTS_STATEMENTS_ROUTE), [navigate]);

  useEffect(() => {
    if (isError && 'status' in error) {
      if (error.status === NOT_FOUND_ERROR_STATUS) {
        handleGoBack();
      }

      if (error.status === NO_PERMISSION_ERROR_STATUS) {
        navigate(NO_PERMISSION_ROUTE);
      }
    }
  }, [error, handleGoBack, isError, navigate]);

  const handleEmployerCancel = () => {
    setModalType('employerCancel');
  };

  const handleCloseEmployerCancel = () => {
    setModalType('');
  };

  const tabs = getTabItems(approvers);

  const description = taskData ? (
    <Flex gap="8" align="center">
      {!!totalDeadlineAt && (
        <>
          <Typography.Text
            size="14"
            type={isPlannedGreaterThanCurrent ? 'danger' : 'secondary'}>{`${t(
            'common_planned_revision_date',
          )}: `}</Typography.Text>
          <Flex gap="4" align="center">
            {isPlannedGreaterThanCurrent && <Icon name="attention" color="var(--color-error)" />}
            <Typography.Text type={isPlannedGreaterThanCurrent ? 'danger' : 'secondary'}>
              {dayjs.utc(totalDeadlineAt).local().format('DD MMMM YYYY, HH:mm')}
            </Typography.Text>
          </Flex>
        </>
      )}

      <Flex align="center" gap="8">
        {`${t('common_last_change')}: `}
        <Flex gap="4" align="center">
          <Icon name="calendar" />
          <Typography.Text type="secondary">
            {dayjs.utc(taskData?.updatedAt).local().format('DD MMMM YYYY, HH:mm')}
          </Typography.Text>
        </Flex>
      </Flex>
    </Flex>
  ) : null;

  return (
    <>
      <DocumentEmployerCancelModal
        open={modalType === 'employerCancel'}
        onCancel={handleCloseEmployerCancel}
        type="document"
        id={id}
      />
      <WithOverflow>
        <ContentHeader align="flex-start" showBorder>
          <ContentHeader.Title goBackLink={DOCUMENTS_STATEMENTS_ROUTE} description={description}>
            {t('common_view_statement')}
          </ContentHeader.Title>
          <ContentHeader.Actions>
            <KedoStatusTag currentKedoStatus={taskData?.currentKedoStatus} size="30" />
          </ContentHeader.Actions>
        </ContentHeader>
        <WithLoader isLoading={isLoading}>
          <DocumentView isError={isError}>
            <DocumentView.Content>
              <Flex vertical gap="24">
                <DocumentView.Employee employee={taskData?.employee} />

                <Flex vertical gap="8">
                  <Typography.Title level={5}>{t('common_statement')}</Typography.Title>
                  <Flex vertical>
                    <Typography.Text size="14" type="secondary">
                      {taskData?.category.name}
                    </Typography.Text>
                    <Typography.Text size="14">{taskData?.statement.name}</Typography.Text>
                  </Flex>
                </Flex>
                <DocumentView.Tabs items={tabs} />
              </Flex>
              <DocumentView.History historyItems={taskData?.history as StepsProps['items']} />
            </DocumentView.Content>
            <Flex gap="8" className={s.actions}>
              {showCancelButton && (
                <Button leftIcon="unpublish" fullWidth danger onClick={handleEmployerCancel}>
                  {t('common_cancel_employer')}
                </Button>
              )}
              <Button fullWidth type="secondary" onClick={handleGoBack}>
                {t('common_close')}
              </Button>
            </Flex>
          </DocumentView>
        </WithLoader>
      </WithOverflow>
    </>
  );
};

export default DocumentsView;
