import { useNavigate } from 'react-router-dom';
import { DOCUMENTS_VACATIONS_ROUTE } from 'routes/documents/list';
import { t } from 'tools/i18n';

import { Form } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';
import FiltersV2 from 'containers/filters-v2';
import WithOverflow from 'containers/wrappers/with-overflow';

import { KedoDocumentType } from 'types/documents.types';

import s from './DocumentsVacations.module.scss';
import Filters from './containers/filters';
import { DEFAULT_FORM_STATE, FormResponseType } from './documentsVacations.utils';
import useColumns from './useColumns.hook';
import useGetData from './useGetData.hook';

const DocumentsVacations = () => {
  const navigate = useNavigate();

  const [form] = Form.useForm<FormResponseType>();

  const { data, isFetching, isLoading } = useGetData({ form });

  const columns = useColumns();

  const handleOnRow = (record: KedoDocumentType) => ({
    onClick: () => navigate(`${DOCUMENTS_VACATIONS_ROUTE}/${record.id}`),
  });

  return (
    <WithOverflow>
      <ContentHeader>
        <ContentHeader.Title>{t('documents_vacations_annual')}</ContentHeader.Title>
      </ContentHeader>
      <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
        <Filters />
        <FiltersV2.Table<KedoDocumentType, FormResponseType>
          className={s.table}
          isInitialLoading={isLoading}
          scroll={{ y: 800, x: 700 }}
          dataSource={data?.content}
          columns={columns}
          rowKey="id"
          onRow={handleOnRow}
          total={data?.totalCount}
          loading={isFetching}
        />
      </FiltersV2>
    </WithOverflow>
  );
};

export default DocumentsVacations;
