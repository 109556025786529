import { ChangeEvent, useEffect, useState } from 'react';

import { useDebounce } from 'utils/useDebounce.hook';

import {
  useGetKedoDocumentStatusesQuery,
  useGetTaskStatuses1cQuery,
} from 'services/1c/cApiService';
import { useLazyGetDivisionsQuery } from 'services/division/divisionApiService';
import { GetDivisionsPropsType } from 'services/division/divisionApiService.types';
import { useLazyGetCategoriesQuery } from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService';
import { GetCategoriesPropsType } from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService.types';
import { useLazyGetEmployersListQuery } from 'services/employer/employerApiService';
import { GetEmployersPropsType } from 'services/employer/employerApiService.types';
import { useLazyGetPositionsQuery } from 'services/position/positionApiService';
import { GetPositionsPropsType } from 'services/position/positionApiService.types';

import { KEDO_TYPE_ENUM } from 'types/kedo.types';

import {
  INITIAL_REQUEST_BODY,
  RequestBody,
  selectFromResult,
  selectFromResultWithFilter,
  simpleSelectFromResult,
} from './documents.utils';

interface useDocumentsFiltersProps {
  types?: KEDO_TYPE_ENUM[];
  categoryType?: KEDO_TYPE_ENUM;
  usePositionFilter?: boolean;
  useDivisionFilter?: boolean;
}

const DEFAULT_KEDO_TYPES = [KEDO_TYPE_ENUM.KEDO, KEDO_TYPE_ENUM.BPMN];

const useDocumentsFilters = (props: useDocumentsFiltersProps) => {
  const { types, categoryType, usePositionFilter = false, useDivisionFilter = false } = props;

  const [requestBody, setRequestBody] = useState<RequestBody>(INITIAL_REQUEST_BODY);

  const { debouncedValue: debouncedRequestBody } = useDebounce(requestBody);

  const [getEmployers, employersResult] = useLazyGetEmployersListQuery({ selectFromResult });
  const [getCategories, categoriesResult] = useLazyGetCategoriesQuery();
  const [getDivisions, divisionsResult] = useLazyGetDivisionsQuery({
    selectFromResult: simpleSelectFromResult,
  });
  const [getPositions, positionsResult] = useLazyGetPositionsQuery({
    selectFromResult: selectFromResultWithFilter,
  });
  const { data: taskStatuses = [] } = useGetTaskStatuses1cQuery({
    types: types ?? DEFAULT_KEDO_TYPES,
    isFilter: true,
  });
  const { data: kedoDocumentStatuses } = useGetKedoDocumentStatusesQuery();

  const employersSearchHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setRequestBody((prev) => ({ ...prev, employerName: e.target.value }));
  };

  const categoriesSearchHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setRequestBody((prev) => ({ ...prev, categoryName: e.target.value }));
  };

  const divisionsSearchHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setRequestBody((prev) => ({ ...prev, divisionName: e.target.value }));
  };

  const positionsSearchHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setRequestBody((prev) => ({ ...prev, positionName: e.target.value }));
  };

  useEffect(() => {
    const defaultBody: GetEmployersPropsType = {
      page: debouncedRequestBody.page!,
      size: 500,
    };

    if (debouncedRequestBody.employerName) {
      defaultBody.name = debouncedRequestBody.employerName;
    }

    getEmployers(defaultBody);
  }, [debouncedRequestBody, getEmployers]);

  useEffect(() => {
    const defaultBody: GetCategoriesPropsType = {};

    if (debouncedRequestBody.categoryName) {
      defaultBody.name = debouncedRequestBody.categoryName;
    }

    if (categoryType) {
      defaultBody.type = categoryType;
    }

    getCategories({ ...defaultBody });
  }, [debouncedRequestBody, getCategories, categoryType]);

  useEffect(() => {
    if (usePositionFilter) {
      let defaultBody: GetPositionsPropsType = {};

      if (debouncedRequestBody.positionName) {
        defaultBody.name = debouncedRequestBody.positionName;
      }

      getPositions(defaultBody);
    }
  }, [usePositionFilter, debouncedRequestBody, getPositions]);

  useEffect(() => {
    if (useDivisionFilter) {
      let defaultBody: GetDivisionsPropsType = {};

      if (debouncedRequestBody.divisionName) {
        defaultBody.name = debouncedRequestBody.divisionName;
      }

      getDivisions(defaultBody);
    }
  }, [useDivisionFilter, debouncedRequestBody, getDivisions]);

  return {
    kedoDocumentStatuses,
    taskStatuses,
    employersResult,
    categoriesResult,
    positionsResult,
    divisionsResult,
    employersSearchHandler,
    categoriesSearchHandler,
    positionsSearchHandler,
    divisionsSearchHandler,
  };
};

export default useDocumentsFilters;
