import { FC } from 'react';

import cn from 'classnames';
import { useParams } from 'react-router-dom';
import { sortDocumentsFiles } from 'utils/files.utils';

import { Flex, Icon, Typography } from 'gazprom-ui-lib';

import { KedoDocumentType } from 'types/documents.types';
import { FileType } from 'types/file.types';

import FileDownload from '../../../../containers/file-download';
import s from './Files.module.scss';
import { getFileUrl } from './files.utils';

interface FilesProps {
  data?: KedoDocumentType;
}

const Files: FC<FilesProps> = (props) => {
  const { data } = props;
  const { id } = useParams();

  if (!data?.files.length) {
    return null;
  }

  const renderFile = (file: FileType) => {
    const { fileKey, fileName } = file;
    const fileDownloadUrl = getFileUrl(id!, fileKey);

    return (
      <Flex
        key={fileKey}
        justify="space-between"
        align="center"
        gap="12"
        className={cn(s.download)}>
        <Flex align="center" gap="12">
          <Icon name="image" className={s.icon} />
          <Flex vertical gap="4" className={s.fileDescription}>
            <Typography.Text weight="500" size="14" type="primary">
              {fileName}
            </Typography.Text>
          </Flex>
        </Flex>
        <FileDownload fileName={fileName} fileDownloadUrl={fileDownloadUrl} />
      </Flex>
    );
  };

  return (
    <div className={s.fileWrapper}>
      {[...(data?.files ?? [])].sort(sortDocumentsFiles).map(renderFile)}
    </div>
  );
};

export default Files;
