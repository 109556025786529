export const DOCUMENT_TYPES = [
  {
    value: 'ADD_CONTRACT',
    label: 'Дополнительное соглашение',
  },
  {
    value: 'SIGN_PERSON',
    label: 'Документ на подпись сотрудником',
  },
  {
    value: 'SIGN_TWO_SIDES',
    label: 'Документ на подпись организацией и сотрудником',
  },
  {
    value: 'PERS_DATA_AGREEMENT',
    label: 'Согласие на обработку персональных данных',
  },
];

export enum ADDITIONAL_DOCUMENTS_ENUM {
  NAME = 'name',
  DOC_NUMBER = 'docNumber',
  DOC_DATE = 'docDate',
  DOC_TYPE = 'docType',
  SGNER_ID = 'signerId',
  FILE = 'file',
  KEDO_DOCUMENT_UUID = 'kedoDocumentUuid',
}

export type AddDocumentFormResponse = {
  [ADDITIONAL_DOCUMENTS_ENUM.NAME]: string;
  [ADDITIONAL_DOCUMENTS_ENUM.DOC_NUMBER]: string;
  [ADDITIONAL_DOCUMENTS_ENUM.DOC_DATE]: string;
  [ADDITIONAL_DOCUMENTS_ENUM.DOC_TYPE]: string;
  [ADDITIONAL_DOCUMENTS_ENUM.SGNER_ID]: string;
  [ADDITIONAL_DOCUMENTS_ENUM.FILE]: {
    fileName: string;
    fileKey: string;
  };
  [ADDITIONAL_DOCUMENTS_ENUM.KEDO_DOCUMENT_UUID]: string;
};
