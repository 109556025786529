import { useEffect } from 'react';

import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';

import { useGetDeclarationTypeQuery } from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService';

import { Checkbox, Form, Typography } from 'gazprom-ui-lib';

import DocumentsDeclarationsFormCheck from 'containers/documents-declarations-form/documents-declarations-form-check';
import { DOCUMENTS_PARAMETERS_DECLARATIONS_FORM_ENUM } from 'containers/documents-declarations-form/documentsDeclarationsForm.utils';
import DocumentsDeclarationsFormBpmnProcess from 'containers/documents-declarations-form/documnets-declarations-form-bpnm-process/documentsDeclarationsFormBpmnProcess';
import useBPMNProcesses from 'containers/documents-declarations-form/useBPMNProcess.hook';
import WithLoader from 'containers/wrappers/with-loader';

import { ProcessParamType } from 'types/documents.types';
import { KEDO_TYPE_ENUM } from 'types/kedo.types';

import DocumentsDeclarationsButtons from '../documents-declarations-buttons';
import s from './DocumentsDeclarationsParameters.module.scss';
import ParametersTree from './containers/parameters-tree';
import StageDeadline from './containers/stage-deadline';
import {
  CANCEL_ORDERS_PROCESS,
  DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_ENUM,
  DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_NAME,
} from './documentsDeclarationsParameters.utils';
import useParametersFormSubmitHandler from './useParametersFormSubmitHandler.hook';

const DocumentsDeclarationsParameters = () => {
  const { id } = useParams<{ id: string }>();
  const { dataObject, isLoading: bpmnIsLoading } = useBPMNProcesses();
  const { data: declarationData, isLoading: declarationDataIsLoading } = useGetDeclarationTypeQuery(
    id ?? skipToken,
  );

  const [form] = Form.useForm();
  const bpmnProcess = Form.useWatch(DOCUMENTS_PARAMETERS_DECLARATIONS_FORM_ENUM.BPMN_PROCESS, form);
  const processParameters = dataObject[bpmnProcess]?.params;
  const cancelProcessParameters = dataObject[CANCEL_ORDERS_PROCESS]?.params ?? [];

  const showIsCancellableParameter = declarationData?.type === KEDO_TYPE_ENUM.BPMN;
  const showHasDigitalForm = declarationData?.type === KEDO_TYPE_ENUM.KEDO;
  const showBpmnProcess = declarationData?.type !== KEDO_TYPE_ENUM.KEDO;

  const isCancellable = Form.useWatch(
    DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_ENUM.IS_CANCELABLE,
    form,
  );

  const { isLoading, handleFormSubmit, setParamValues } = useParametersFormSubmitHandler({
    isCancellable,
  });

  const renderComponent = (params: ProcessParamType) => {
    const { type, code, name } = params;
    const fieldName = `${code}`;
    const currentParam = declarationData?.cancelProcessParams?.find((el) => el.code === code);
    const defaultValue = currentParam?.value;

    return (
      <DocumentsDeclarationsFormCheck
        label={name}
        formName={fieldName}
        className={s.check}
        key={`${type}.${code}.${name}`}
        defaultValue={defaultValue === 'true'}
      />
    );
  };

  useEffect(() => {
    if (declarationData?.params) {
      const newParams = new Map(
        declarationData?.params.map((param) => [
          param.code,
          { ...param, numberOfDays: param.numberOfDays ?? 0 },
        ]),
      );

      declarationData?.params?.forEach((param) => {
        if (param.type === 'STRING') {
          form.setFieldValue(param.code, param.value);
        }

        form.setFieldValue(`${param.code}.numberOfDays`, param.numberOfDays);
      });

      setParamValues(newParams);
    }
  }, [declarationData, form, setParamValues]);

  return (
    <WithLoader isLoading={bpmnIsLoading || declarationDataIsLoading}>
      <Form
        form={form}
        onFinish={handleFormSubmit}
        id={DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_NAME}>
        <div className={s.container}>
          <StageDeadline
            name={DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_ENUM.TOTAL_DAYS_LIMIT}
            label={
              <Typography.Title level={5}>
                {t('documents_declarations_common_deadline')}
              </Typography.Title>
            }
            initialValue={declarationData?.totalDaysLimit}
          />
          {showBpmnProcess && (
            <DocumentsDeclarationsFormBpmnProcess defaultValue={declarationData?.bpmnProcess} />
          )}
          {processParameters && (
            <ParametersTree
              processParameters={processParameters}
              setParamValues={setParamValues}
              defaultParams={declarationData?.params ?? []}
            />
          )}
          {showIsCancellableParameter && (
            <Form.Item
              initialValue={declarationData?.isCancellable}
              name={DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_ENUM.IS_CANCELABLE}
              valuePropName="checked">
              <Checkbox>{t('documents_declarations_parameters_cancel_title')}</Checkbox>
            </Form.Item>
          )}
          {isCancellable && cancelProcessParameters?.map(renderComponent)}
          {showHasDigitalForm && (
            <Form.Item
              initialValue={declarationData?.hasDigitalForm}
              name={DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_ENUM.HAS_DIGITAL_FORM}
              valuePropName="checked">
              <Checkbox>{t('documents_declarations_parameters_digital_title')}</Checkbox>
            </Form.Item>
          )}
        </div>
        <DocumentsDeclarationsButtons isFetching={isLoading} />
      </Form>
    </WithLoader>
  );
};

export default DocumentsDeclarationsParameters;
