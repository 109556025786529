import { useNavigate, useParams } from 'react-router-dom';
import { DECLARATIONS_TYPE_TAB_ENUM, getDeclarationsTabTypeRoute } from 'routes/settings/list';
import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import { Button, Form, Icon, Modal, Select, Typography } from 'gazprom-ui-lib';

import s from './ModalCreate.module.scss';
import {
  DECLARATIONS_MODAL_FORM_ENUM,
  DECLARATIONS_OPTIONS_TYPE,
  DeclarationsFieldType,
  selectOptions,
} from './modalCreate.utils';

interface Props {
  open: boolean;
  close: () => void;
  type: DECLARATIONS_TYPE_TAB_ENUM;
}

const ModalCreate = (props: Props) => {
  const { open, type, close } = props;
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();

  const handleFinish = (params: DeclarationsFieldType) => {
    if (params[DECLARATIONS_MODAL_FORM_ENUM.TYPE] === DECLARATIONS_OPTIONS_TYPE.SOCIAL) {
      navigate(`${getDeclarationsTabTypeRoute(type)}/social`);
      return;
    }

    navigate(`${getDeclarationsTabTypeRoute(type)}/edo`, { state: { parentStatementId: id } });
  };

  return (
    <Modal open={open} className={s.container} onCancel={close}>
      <div className={s.header}>
        <Typography.Title level={4}>
          {t('documents_declarations_add_declarations')}
        </Typography.Title>
        <button className={s.close} onClick={close}>
          <Icon name="delete" size={14} />
        </button>
      </div>

      <div className={s.content}>
        <Typography.Text size="14" weight="500" type="primary">
          {t('common_section')}
        </Typography.Text>
        <Form form={form} onFinish={handleFinish}>
          <Form.Item name={DECLARATIONS_MODAL_FORM_ENUM.TYPE} rules={[formRules.required]}>
            <Select
              placeholder={t('common_select_second')}
              className={s.select}
              options={selectOptions}
            />
          </Form.Item>
        </Form>
      </div>

      <div className={s.buttonContainer}>
        <Button type="primary" leftIcon="plus" fullWidth onClick={() => form.submit()}>
          {t('common_add')}
        </Button>
        <Button type="secondary" fullWidth onClick={close}>
          {t('common_close')}
        </Button>
      </div>
    </Modal>
  );
};

export default ModalCreate;
