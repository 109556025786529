import { useState } from 'react';

import { t } from 'tools/i18n';
import { createPersonalFileUploadUrl } from 'utils/createDownloadUrl.utils';
import { createMediaUploadUrl } from 'utils/createMediaUrl.utils';
import { userManager } from 'utils/keycloak';

import { UploadProps } from 'gazprom-ui-lib';

import { BACKEND_V1_BASE_URL } from 'constants/apiUrls';

interface UseUploadFileProps {
  setErrors: (errors: string[]) => void;
  isPersonalFile?: boolean;
  kedoReviewTaskId?: string | null;
  customUploadUrl?: string;
  customMetaUrl?: string;
}

const useUploadFile = (props: UseUploadFileProps) => {
  const {
    setErrors,
    isPersonalFile = false,
    kedoReviewTaskId,
    customUploadUrl,
    customMetaUrl,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [fileData, setFileData] = useState<{ size?: number; name?: string; url?: string }>({});

  const getStatus = async (key: string): Promise<{ status?: string; error: string }> => {
    const user = await userManager.getUser();
    const token = user?.access_token as string;

    let metaUrl = `${BACKEND_V1_BASE_URL}media/meta`;

    if (isPersonalFile || kedoReviewTaskId) {
      metaUrl = `${BACKEND_V1_BASE_URL}me/files/meta`;
    }

    if (customMetaUrl) {
      metaUrl = customMetaUrl;
    }

    return fetch(`${metaUrl}/${key}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        const { status } = response;

        if (status === 'INFECTED') {
          return Promise.reject('INFECTED');
        }
        if (status === 'VERIFIED') {
          return response;
        }

        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(getStatus(key));
          }, 1000);
        });
      })
      .catch((error) => {
        console.error('Request failed:', error);
        throw error;
      });
  };

  const uploadData = (file: File, token: string) => {
    const formData = new FormData();
    formData.append('file', file);

    let uploadUrl = `${BACKEND_V1_BASE_URL}media`;

    if (isPersonalFile) {
      uploadUrl = `${BACKEND_V1_BASE_URL}me/files`;
    }

    if (kedoReviewTaskId) {
      uploadUrl = `${BACKEND_V1_BASE_URL}me/files/kedo-review-task?kedoReviewTaskId=${kedoReviewTaskId}`;
    }

    if (customUploadUrl) {
      uploadUrl = customUploadUrl;
    }

    return fetch(uploadUrl, {
      method: 'POST',
      credentials: 'include',
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => res.json());
  };

  const customRequest: UploadProps['customRequest'] = async (e) => {
    setIsLoading(true);
    setFileData({});

    const user = await userManager.getUser();
    const token = user?.access_token as string;
    const typedFile = e.file as File;

    uploadData(typedFile, token)
      .then(async (res) => {
        const fileKey = res.fileKey;
        await getStatus(fileKey);

        let uploadUrl;

        if (isPersonalFile || kedoReviewTaskId) {
          uploadUrl = createPersonalFileUploadUrl(fileKey);
        } else {
          uploadUrl = createMediaUploadUrl(fileKey);
        }

        setFileData({
          size: typedFile.size,
          name: typedFile.name,
          url: uploadUrl,
        });
      })
      .catch(() => {
        setErrors([t('validation_something_went_wrong')]);
      })
      .finally(() => setIsLoading(false));
  };

  return {
    isLoading,
    fileData,
    customRequest,
  };
};

export default useUploadFile;
