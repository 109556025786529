import { MouseEventHandler, useCallback, useMemo } from 'react';

import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';
import downloadHandler from 'utils/downloadHandler';

import { useSendRecruitByIdMutation } from 'services/recruit/recruitApiService';
import { SendRecruitById } from 'services/recruit/recruitApiService.types';

import { Button, TableProps, Typography } from 'gazprom-ui-lib';

import TableStatus from 'components/table/table-status';
import { STATUSES } from 'components/table/table-status/tableStatus.utils';

import { RecruitDocumentType } from 'types/recruit.types';

interface Props {
  hideDownload?: boolean;
}

const useColumnsHook = (props: Props): TableProps<RecruitDocumentType>['columns'] => {
  const { hideDownload } = props;

  const { id } = useParams<{ id: string }>();

  const [sendFile, { isLoading }] = useSendRecruitByIdMutation();

  const handleSend = useCallback(
    ({ kedoDocumentId, recruitLinkKedoDocumentId }: Omit<SendRecruitById, 'id'>) => {
      if (id && kedoDocumentId && recruitLinkKedoDocumentId) {
        sendFile({ id, kedoDocumentId, recruitLinkKedoDocumentId });
      }
    },
    [id, sendFile],
  );

  const handleDownload = useCallback((row: RecruitDocumentType) => {
    let url = `${process.env.https://admin-gateway.dev.tockl.ru/api/v1/}recruit/download/document?recruitDocumentId=${row.id}`;

    downloadHandler({
      url,
      fileName: '',
      headersFileNameKey: 'filename',
    });
  }, []);

  const renderFile = useCallback(
    (_: unknown, row: RecruitDocumentType) => {
      if (row.orgSignStatus.code === 'NOT_SET') {
        const kedoDocumentId = row?.document.id;
        const recruitLinkKedoDocumentId = row?.id;

        const onclick: MouseEventHandler<HTMLElement> = (event) => {
          event.stopPropagation();
          return handleSend({ kedoDocumentId, recruitLinkKedoDocumentId });
        };

        return (
          <Button type="link" leftIcon="signed" onClick={onclick} loading={isLoading}>
            {t('common_send')}
          </Button>
        );
      }

      return row.document.files[0] && !hideDownload ? (
        <Button
          leftIcon="download"
          onlyIcon
          type="link"
          onClick={(event) => {
            event.stopPropagation();
            handleDownload(row);
          }}
        />
      ) : null;
    },
    [handleDownload, handleSend, hideDownload, isLoading],
  );

  return useMemo(
    () => [
      {
        title: t('common_document_name'),
        dataIndex: '',
        render: (_, row: RecruitDocumentType) => (
          <Typography.Text weight="500" size="14">
            {row.document.name}
          </Typography.Text>
        ),
      },
      {
        title: t('documents_recruit_org_sign_status'),
        dataIndex: '',
        render: (_, row: RecruitDocumentType) => (
          <TableStatus status={row.orgSignStatus.state.toUpperCase() as STATUSES}>
            {row.orgSignStatus.title}
          </TableStatus>
        ),
      },
      {
        title: t('documents_recruit_employee_sign_status'),
        dataIndex: '',
        render: (_, row: RecruitDocumentType) => (
          <TableStatus status={row.employeeSignStatus.state.toUpperCase() as STATUSES}>
            {row.employeeSignStatus.title}
          </TableStatus>
        ),
      },
      {
        title: '',
        width: 180,
        render: renderFile,
      },
    ],
    [renderFile],
  );
};

export default useColumnsHook;
