import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import { DatePicker, Flex, Form, Input, Typography } from 'gazprom-ui-lib';

import { ADDITIONAL_DOCUMENTS_ENUM } from '../../utils';
import s from './NumberAndDate.module.scss';

const NumberAndDate = () => {
  return (
    <Flex gap="16" className={s.container}>
      <Form.Item
        label={
          <Typography.Text size="14" type="primary" weight="500">
            {t('common_document_number')}
          </Typography.Text>
        }
        name={ADDITIONAL_DOCUMENTS_ENUM.DOC_NUMBER}
        rules={[formRules.required]}>
        <Input placeholder={t('common_enter_text')} />
      </Form.Item>
      <Form.Item
        label={
          <Typography.Text size="14" type="primary" weight="500">
            {t('common_document_date')}
          </Typography.Text>
        }
        name={ADDITIONAL_DOCUMENTS_ENUM.DOC_DATE}
        rules={[formRules.required]}>
        <DatePicker placeholder={t('common_select_date')} format="DD.MM.YYYY" />
      </Form.Item>
    </Flex>
  );
};

export default NumberAndDate;
