import { useMemo, useState } from 'react';

import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';
import { v4 as uuidv4 } from 'uuid';

import { Form, Typography } from 'gazprom-ui-lib';

import ImgPreview from 'components/img-preview';
import UploadImitation from 'components/upload-imitation';
import ImageModal from 'containers/image-modal';
import { FORM_IMAGE_ENUM, FormImageResponse } from 'containers/image-modal/imageModal.utils';

import { ADDITIONAL_DOCUMENTS_ENUM } from '../../utils';
import s from './File.module.scss';
import { getDownloadLink, getMetaLink, getUploadLink } from './file.utils';

interface KedoReviewTaskFormFileProps {
  showDownload?: boolean;
  showDelete?: boolean;
}

const File = (props: KedoReviewTaskFormFileProps) => {
  const { showDelete, showDownload } = props;

  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);

  const form = Form.useFormInstance();
  const cover = Form.useWatch(ADDITIONAL_DOCUMENTS_ENUM.FILE);

  const { uploadLink, metaLink, kedoDocumentUuid } = useMemo(() => {
    const kedoDocumentUuid = uuidv4();

    return {
      kedoDocumentUuid,
      uploadLink: getUploadLink(kedoDocumentUuid),
      metaLink: getMetaLink(),
    };
  }, []);

  const handleModalOpenClose = () => {
    setShowUploadModal((prevState) => !prevState);
  };

  const onFileUpload = (_: string, data: FormImageResponse) => {
    const fileKey = data[FORM_IMAGE_ENUM.URL].replace('api/v1/media/download/kedo-document/', '');
    const fileName = data[FORM_IMAGE_ENUM.FULL_INFO].name;

    form.setFieldValue(ADDITIONAL_DOCUMENTS_ENUM.FILE, {
      fileName,
      fileKey,
    });
    form.setFieldValue(ADDITIONAL_DOCUMENTS_ENUM.KEDO_DOCUMENT_UUID, kedoDocumentUuid);

    handleModalOpenClose();
  };

  const resetImage = () => {
    form.resetFields([ADDITIONAL_DOCUMENTS_ENUM.FILE]);
  };

  const coverSize: number = cover?.fileName
    ? Number((Number(cover?.size) / (1024 * 1024)).toFixed(2))
    : 0;

  return (
    <div className={s.wrapper}>
      <Typography.Text size="14" weight="500" type="primary">
        {t('common_file')}
      </Typography.Text>
      <Form.Item name={ADDITIONAL_DOCUMENTS_ENUM.FILE} rules={[formRules.required]}>
        {cover ? (
          <ImgPreview
            downloadWithAuth
            iconName="docTypePdf"
            className={s.preview}
            resetImage={resetImage}
            type="icon"
            showDelete={showDelete}
            showDownload={showDownload}
            url={getDownloadLink(`me/files/download/${cover?.fileKey}`)}
            title={cover?.fileName ?? getDownloadLink(`me/files/download/${cover?.fileKey}`)}
            description={t('common_mb', { count: isNaN(coverSize) ? 0 : coverSize })}
          />
        ) : (
          <UploadImitation
            title={t('common_upload_a_file')}
            handleClick={handleModalOpenClose}
            description={t('validation_upload_file_format', { name: 'pdf' })}
          />
        )}
      </Form.Item>
      <ImageModal
        customUploadUrl={uploadLink}
        customMetaUrl={metaLink}
        maxLimitSizeMB={100}
        isOpen={showUploadModal}
        onSubmit={onFileUpload}
        onClose={handleModalOpenClose}
        uploadTitle={t('common_upload_a_file')}
        uploadDescription={t('validation_upload_file_format', { name: 'pdf' })}
        availableTypes={AVAILABLE_TYPES}
      />
    </div>
  );
};

const AVAILABLE_TYPES = ['application/pdf'];

export default File;
