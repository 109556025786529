export enum KEDO_TYPE_ENUM {
  KEDO = 'KEDO',
  SOCIAL = 'SOCIAL',
  BPMN = 'BPMN',
  REVIEW = 'REVIEW',
  VACATION = 'VACATION',
}

export type KedoStatusType = {
  code: string;
  type: string;
  nameOwner: string;
  name: string;
  description: string;
  state: string;
  isFilter: boolean;
};

export enum KEDO_STATES {
  PENDING = 'pending',
  DONE = 'done',
  ERROR = 'error',
  CONTINUE = 'continue',
}

export enum KEDO_ATTRIBUTE_VALIDATION_CODES {
  NO_LATER_CURRENT = 'NO_LATER_CURRENT',
  NO_LATER_3_DAYS = 'NO_LATER_3_DAYS',
  NO_LATER_5_DAYS = 'NO_LATER_5_DAYS',
  NO_LATER_7_DAYS = 'NO_LATER_7_DAYS',
}

export enum KEDO_ATTRIBUTE_VALIDATION_TYPES {
  DATE = 'DATE',
}

export type KedoAttributeValidation = {
  code: KEDO_ATTRIBUTE_VALIDATION_CODES;
  title: string;
  errorText: string;
  attributeType: KEDO_ATTRIBUTE_VALIDATION_TYPES;
};
