import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useDebounce } from 'utils/useDebounce.hook';

import { useGetKedoArchiveDocsQuery } from 'services/kedo-archive-doc/kedoArchiveDocApiService';
import { FilledValuesPropsType } from 'services/kedo-archive-doc/kedoArchiveDocApiService.types';

import { Form, FormInstance } from 'gazprom-ui-lib';

import { FormResponseType } from './documentsArchiveInfo.utils';

interface UseGetDataProps {
  form: FormInstance<FormResponseType>;
}

const useGetData = (props: UseGetDataProps) => {
  const { form } = props;

  const formData = Form.useWatch<FormResponseType>([], form);

  const { debouncedValue: debouncedFormData, isDebouncing } = useDebounce(formData);

  const { order, property, search, filters } = debouncedFormData ?? {};

  const { filledValues, ...restFilters } = filters ?? {};

  const sort = order && property ? [{ order, property }] : undefined;

  const filledValuesFilters: FilledValuesPropsType = {};

  if (filledValues?.includes('noDate')) {
    filledValuesFilters['noDate'] = false;
  }

  if (filledValues?.includes('noType')) {
    filledValuesFilters['noType'] = false;
  }

  if (filledValues?.includes('noNumber')) {
    filledValuesFilters['noNumber'] = false;
  }

  const { data: kedoArchiveDocs, isFetching: kedoArchiveDocsIsFetching } =
    useGetKedoArchiveDocsQuery(
      debouncedFormData
        ? {
            sort,
            page: debouncedFormData.page,
            size: debouncedFormData.size,
            fio: search,
            ...filledValuesFilters,
            ...restFilters,
          }
        : skipToken,
    );

  return {
    kedoArchiveDocs,
    kedoArchiveDocsIsFetching: kedoArchiveDocsIsFetching || isDebouncing,
  };
};

export default useGetData;
