import { useParams } from 'react-router-dom';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { Form } from 'gazprom-ui-lib';

import DocumentsDeclarationsFormCategory from 'containers/documents-declarations-form/documents-declarations-form-category';
import DocumentsDeclarationsFormCheck from 'containers/documents-declarations-form/documents-declarations-form-check';
import DocumentsDeclarationsFormDescription from 'containers/documents-declarations-form/documents-declarations-form-description';
import DocumentsDeclarationsFormExecutor from 'containers/documents-declarations-form/documents-declarations-form-executor';
import DocumentsDeclarationsFormName from 'containers/documents-declarations-form/documents-declarations-form-name';
import DocumentsDeclarationsFormOrganization from 'containers/documents-declarations-form/documents-declarations-form-organization';
import { DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM } from 'containers/documents-declarations-form/documentsDeclarationsForm.utils';
import WithLoader from 'containers/wrappers/with-loader';

import DocumentsDeclarationsButtons from 'pages/documents/documents-declarations/documents-declarations-buttons';
import useCommonFormSubmitHandler from 'pages/documents/documents-declarations/useCommonFormSubmitHandler.hook';

import { KEDO_TYPE_ENUM } from 'types/kedo.types';

import usePermissionCheck from '../../usePermissionCheck';
import LinkedStatements from '../linked-statements';
import s from './CommonDescriptionCommonTab.module.scss';
import { DOCUMENTS_DECLARATIONS_EDO_COMMON_FORM_NAME } from './common.utils';
import useCommonData from './useCommonData';

const CommonDescriptionCommonTab = () => {
  const [form] = Form.useForm();

  const [selectedEmployee] = useFullSelectedEmployee();
  const { id } = useParams<{ id: string }>();
  const { isLoading, statementData, createRoute } = useCommonData({ form });

  const statementType = id ? statementData?.type : KEDO_TYPE_ENUM.BPMN;
  const { isFetching, handleFormSubmit } = useCommonFormSubmitHandler({
    createRoute: createRoute,
    type: statementType,
  });

  const isAdmin = usePermissionCheck(['admin_mobile', 'admin']);
  const isKedoAdmin = usePermissionCheck(['kedo_admin']);

  const isOrgDisabled = isKedoAdmin && !isAdmin;
  const defaultOrgValue = isOrgDisabled ? selectedEmployee?.employer.id : undefined;

  return (
    <WithLoader isLoading={isLoading}>
      <Form
        form={form}
        onFinish={handleFormSubmit}
        id={DOCUMENTS_DECLARATIONS_EDO_COMMON_FORM_NAME}>
        <div className={s.container}>
          {!id && (
            <DocumentsDeclarationsFormOrganization
              disabled={isOrgDisabled}
              defaultValue={defaultOrgValue}
            />
          )}
          <DocumentsDeclarationsFormName />
          <DocumentsDeclarationsFormCategory type={statementData?.type} />
          <DocumentsDeclarationsFormDescription />
          <DocumentsDeclarationsFormExecutor />
          <DocumentsDeclarationsFormCheck
            defaultValue={false}
            className={s.hidden}
            formName={DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM.ACCESS}
            label=""
          />
          <Form.Item name={DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM.DEFAULT_STATEMENT_ID} noStyle />
        </div>
        <LinkedStatements />
        <DocumentsDeclarationsButtons isFetching={isFetching} />
      </Form>
    </WithLoader>
  );
};

export default CommonDescriptionCommonTab;
