import { Link, useParams } from 'react-router-dom';
import { COMMUNITY_MANAGEMENT_ROUTE } from 'routes/communities/list';
import { t } from 'tools/i18n';

import { useMassSubscribeMutation } from 'services/communities/communitiesApiService';
import { useGetEmployerRootQuery } from 'services/employee-groups-new/employeeGroupsNewApiService';

import { Button, Flex, Form, FormInstance } from 'gazprom-ui-lib';

import WithLoader from 'containers/wrappers/with-loader';

import s from './MassSubscribeInviteForm.module.scss';
import ConfirmModal from './containers/confirm-modal';
import MassSubscribeInviteFormGender from './mass-subscribe-invite-form-gender';
import MassSubscribeInviteFormTree from './mass-subscribe-invite-form-tree';
import {
  DEFAULT_MASS_SUBSCRIBE_INVITE_FORM_VALUES,
  MASS_SUBSCRIBE_INVITE_FORM_NAME,
  MassSubscribeInviteFormResponse,
  preparePayload,
} from './massSubscribeInviteForm.utils';

type MassSubscribeInviteFormProps = {
  form: FormInstance<MassSubscribeInviteFormResponse>;
  isSubscribersPage: boolean;
  confirmModalIsOpened: boolean;
  closeConfirmModal: () => void;
  openConfirmModal: () => void;
  disableSubmit: boolean;
};

const MassSubscribeInviteForm = ({
  form,
  isSubscribersPage,
  confirmModalIsOpened,
  closeConfirmModal,
  openConfirmModal,
  disableSubmit,
}: MassSubscribeInviteFormProps) => {
  const { id: communityId } = useParams<{ id: string }>() as { id: string };
  const { data: rootEmployers = [], isFetching: isFetchingRootEmployers } =
    useGetEmployerRootQuery();

  const [massSubscribe] = useMassSubscribeMutation();

  const handleSubmit = async (payload: MassSubscribeInviteFormResponse) => {
    const preparedPayload = preparePayload(payload);

    try {
      if (isSubscribersPage) {
        await massSubscribe({ communityId, payload: preparedPayload }).unwrap();
      } else {
        console.log(`invited users ${JSON.stringify(preparedPayload)}`);
      }
      form.resetFields();
      closeConfirmModal();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Form
      form={form}
      className={s.formWrapper}
      onFinish={handleSubmit}
      initialValues={DEFAULT_MASS_SUBSCRIBE_INVITE_FORM_VALUES}
      id={MASS_SUBSCRIBE_INVITE_FORM_NAME}>
      <WithLoader isLoading={isFetchingRootEmployers}>
        <MassSubscribeInviteFormGender />
        <MassSubscribeInviteFormTree
          rootEmployers={rootEmployers}
          isFetchingRootEmployers={isFetchingRootEmployers}
        />
        <Flex gap="8" className={s.formActionsWrapper}>
          <Link
            className={s.fullWidth}
            to={`${COMMUNITY_MANAGEMENT_ROUTE}/view/${communityId}/subscribers`}>
            <Button type="secondary" fullWidth form={MASS_SUBSCRIBE_INVITE_FORM_NAME}>
              {t('common_close')}
            </Button>
          </Link>
          <Button
            disabled={disableSubmit}
            onClick={openConfirmModal}
            leftIcon="plus"
            fullWidth
            type="primary"
            form={MASS_SUBSCRIBE_INVITE_FORM_NAME}>
            {t(isSubscribersPage ? 'community_add_multiple_action' : 'community_invite')}
          </Button>
        </Flex>
      </WithLoader>

      <ConfirmModal
        isSubscribersPage={isSubscribersPage}
        open={confirmModalIsOpened}
        close={closeConfirmModal}
      />
    </Form>
  );
};

export default MassSubscribeInviteForm;
