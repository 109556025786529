import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import {
  DECLARATIONS_TYPE_TAB_ENUM,
  getSettingsDeclarationsEdoEditRoute,
  getSettingsDeclarationsKedoEditRoute,
  getSettingsDeclarationsSocialEditRoute,
} from 'routes/settings/list';
import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetDeclarationTypesWithOrgQuery } from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService';
import {
  GetDeclarationsPropsTypes,
  GetDeclarationsWithOrgPropsTypes,
} from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService.types';

import { InputProps } from 'gazprom-ui-lib';

import { DeclarationsTypes } from 'types/documents.types';
import { KEDO_TYPE_ENUM } from 'types/kedo.types';
import { SORT_ENUM } from 'types/sort.types';

import useColumns from './useColumns.hook';

const DEFAULT_REQUEST_BODY = {
  page: 1,
  size: 10,
  order: SORT_ENUM.DESC,
  property: 'createdAt',
};

const useLocalTabData = () => {
  const navigate = useNavigate();

  const [requestBody, setRequestBody] =
    useState<GetDeclarationsWithOrgPropsTypes>(DEFAULT_REQUEST_BODY);
  const [request, { data, isLoading, isFetching }] = useLazyGetDeclarationTypesWithOrgQuery();
  const [filters, setFilters] = useState<Pick<
    GetDeclarationsPropsTypes,
    'categoryId' | 'isPublished' | 'type'
  > | null>(null);

  const { debouncedValue: debouncedRequestBody } = useDebounce(requestBody);

  useEffect(() => {
    const initialRequestBody: GetDeclarationsWithOrgPropsTypes = {
      ...filters,
      page: debouncedRequestBody.page,
      size: debouncedRequestBody.size,
    };

    if (debouncedRequestBody.ownerEmployeeIds) {
      initialRequestBody.ownerEmployeeIds = debouncedRequestBody.ownerEmployeeIds;
    }

    if (debouncedRequestBody.name) {
      initialRequestBody.name = debouncedRequestBody.name;
    }

    if (debouncedRequestBody.property && debouncedRequestBody.order) {
      if (debouncedRequestBody.property === 'createdAt') {
        initialRequestBody.sort = [
          {
            order: debouncedRequestBody.order,
            property: `kedo_statement.create_date`,
          },
        ];
      } else {
        initialRequestBody.sort = [
          {
            order: debouncedRequestBody.order,
            property: `kedo_statement.${debouncedRequestBody.property}`,
          },
        ];
      }
    }

    request(initialRequestBody);
  }, [debouncedRequestBody, filters, request]);

  const columns = useColumns();

  const handleSearchChange: InputProps['onChange'] = (e) => {
    setRequestBody((prevState) => ({ ...prevState, name: e.target.value, page: 1 }));
  };

  const handleOrganizationChange = (id: string) =>
    setRequestBody((prevState) => ({ ...prevState, ownerEmployeeIds: [id], page: 1 }));

  const onFiltersSubmit = (values: Record<string, string[]> | null) => {
    setFilters(values);
    setRequestBody((prevState) => ({ ...prevState, page: 1 }));
  };

  const handleOnRow = (rowData: DeclarationsTypes) => ({
    onClick: () => {
      const { type, id } = rowData ?? {};

      switch (type) {
        case KEDO_TYPE_ENUM.SOCIAL: {
          navigate(getSettingsDeclarationsSocialEditRoute(id, DECLARATIONS_TYPE_TAB_ENUM.LOCAL));
          break;
        }
        case KEDO_TYPE_ENUM.KEDO: {
          navigate(getSettingsDeclarationsKedoEditRoute(id, DECLARATIONS_TYPE_TAB_ENUM.LOCAL));
          break;
        }
        case KEDO_TYPE_ENUM.BPMN:
        default: {
          navigate(getSettingsDeclarationsEdoEditRoute(id, DECLARATIONS_TYPE_TAB_ENUM.LOCAL));
        }
      }
    },
  });

  return {
    data,
    isLoading,
    isFetching,
    columns,
    debouncedRequestBody,
    requestBody,
    filters,
    handleSearchChange,
    onFiltersSubmit,
    handleOnRow,
    setRequestBody,
    handleOrganizationChange,
  };
};

export default useLocalTabData;
