import { FC } from 'react';

import { GetAuthoritiesResponseType } from 'services/crypto/cryptoApiService.types';

import FiltersV2 from 'containers/filters-v2';

import { AuthorityType } from 'types/authority.types';

import { FormResponseType } from '../../settingsCertificationAuthorities.utils';
import useColumns from './useColumns.hook';

interface TableProps {
  dataSource?: GetAuthoritiesResponseType;
  loading: boolean;
  isInitialLoading: boolean;
}

const Table: FC<TableProps> = (props) => {
  const { dataSource, loading, isInitialLoading } = props;
  const columns = useColumns();

  return (
    <FiltersV2.Table<AuthorityType, FormResponseType>
      isInitialLoading={isInitialLoading}
      rowKey="id"
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      total={dataSource?.length}
    />
  );
};

export default Table;
