import { Dayjs } from 'dayjs';

import { SORT_ENUM } from 'types/sort.types';

export type FormResponseType = {
  page: number;
  size: number;
  search?: string;
  property?: string;
  range?: [Dayjs, Dayjs];
  order: SORT_ENUM;
  filters?: {
    statuses?: string[];
    categoryIds?: string[];
    employerIds?: string[];
  } | null;
};

export const DEFAULT_FORM_STATE: FormResponseType = {
  page: 1,
  size: 10,
  search: '',
  property: 'createDate',
  order: SORT_ENUM.DESC,
  filters: null,
};
