import { useMemo } from 'react';

import { t } from 'tools/i18n';

import { ColumnsType, Typography } from 'gazprom-ui-lib';

import TableDate from 'components/table/table-date';
import TableIsPublish from 'components/table/table-is-publish';

import { DeclarationsTypes } from 'types/documents.types';

const renderName = (value: DeclarationsTypes['name']) => (
  <Typography.Text size="12" weight="500">
    {value}
  </Typography.Text>
);

const renderCategoryName = (value: DeclarationsTypes['categoryName']) => (
  <Typography.Text size="12" weight="500" type="secondary">
    {value}
  </Typography.Text>
);

const renderIsPublish = (value: DeclarationsTypes['isPublish']) => (
  <TableIsPublish isPublish={value} />
);

const renderCreatedAt = (value: DeclarationsTypes['createdAt']) => <TableDate date={value} />;

const useColumns = (): ColumnsType<DeclarationsTypes> => {
  return useMemo(
    () => [
      {
        title: t('common_name'),
        dataIndex: 'name',
        key: 'name',
        render: renderName,
      },
      {
        title: t('social_program_category'),
        dataIndex: 'categoryName',
        key: 'categoryName',
        render: renderCategoryName,
      },
      {
        title: t('common_published'),
        dataIndex: 'isPublish',
        key: 'isPublish',
        align: 'center',
        render: renderIsPublish,
      },
      {
        title: t('employee_groups_column_created_date'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        align: 'center',
        render: renderCreatedAt,
        sorter: true,
        defaultSortOrder: 'descend',
      },
    ],
    [],
  );
};

export default useColumns;
