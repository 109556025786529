export enum PERSON_TYPE_ENUM {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

export const getConfig = (state: string) => {
  if (state === 'done') {
    return {
      icon: 'ok',
      type: PERSON_TYPE_ENUM.SUCCESS,
    };
  }

  if (state === 'error') {
    return {
      icon: 'delete',
      type: PERSON_TYPE_ENUM.ERROR,
    };
  }

  return {
    icon: 'time',
    type: PERSON_TYPE_ENUM.WARNING,
  };
};
